import React, { useContext, useEffect, useMemo, useState } from "react";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { Card, Button, Table, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faFilter,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./ReportList.css";
import ExcludedMenuTitles from "../ExcludedMenuTitles/ExcludedMenuTitles";
import FilterModal from "../FilterModal/FilterModal";

const ReportList = () => {
  const { fullMenu } = useContext(SyncReportsContext);
  const [sortedMenu, setSortedMenu] = useState([]);
  const [openMenus, setOpenMenus] = useState([]);
  const [searchOpenMenus, setSearchOpenMenus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [menuType, setMenuType] = useState("all");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const EXCLUDED_MENU_TITLES = ExcludedMenuTitles();

  useEffect(() => {
    const processMenuItems = (menuItems) => {
      const filteredMenu = menuItems.filter(
        (item) =>
          !EXCLUDED_MENU_TITLES.includes(item.title) &&
          item.title !== "Release Notes"
      );

      return filteredMenu
        .map((menuItem) => ({
          ...menuItem,
          reports: menuItem.reports
            ? menuItem.reports
                .filter(
                  (report) =>
                    !report.title.toLowerCase().includes("detail report")
                )
                .sort((a, b) => {
                  const aTitle =
                    menuItem.menu_type === "dashboard" ? a.title : a.menu_title;
                  const bTitle =
                    menuItem.menu_type === "dashboard" ? b.title : b.menu_title;
                  return aTitle.localeCompare(bTitle);
                })
            : [],
        }))
        .sort((a, b) => a.sort_by - b.sort_by);
    };

    setSortedMenu(processMenuItems(fullMenu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullMenu]);

  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, "gi");
    return text.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    );
  };

  const getUniqueBadges = (report) => {
    const badges = report.columns
      .map((column) => column.badge)
      .filter((badge) => badge)
      .filter((badge, index, self) => self.indexOf(badge) === index)
      .sort(); // Sort badges alphabetically
    return badges.join(", ");
  };

  const filteredMenu = useMemo(() => {
    return sortedMenu
      .map((menuItem) => {
        const isMenuTypeMatch =
          menuType === "all" ||
          menuItem.menu_type === menuType ||
          (menuType === "dashboards" && menuItem.menu_type === "dashboard");

        // Updated filter logic to handle both reports and dashboards
        const reports = menuItem.reports.filter((report) => {
          const reportTitle =
            menuItem.menu_type === "dashboard"
              ? report.title
              : report.menu_title;

          // Ensure publish attribute is handled correctly
          const publishValue =
            report.publish != null ? Number(report.publish) : 0;

          const isPublished =
            filter === "all" ||
            (filter === "published" ? publishValue === 1 : publishValue === 0);

          return (
            isPublished && // Ensure the publish filter works for all menu types
            (menuItem.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
              reportTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
              (report.more_info &&
                report.more_info
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())))
          );
        });

        const shouldDisplay =
          isMenuTypeMatch && (reports.length > 0 || searchQuery.length > 0);

        return {
          ...menuItem,
          reports: reports,
          shouldDisplay: shouldDisplay,
        };
      })
      .filter((menuItem) => menuItem.shouldDisplay);
  }, [sortedMenu, searchQuery, filter, menuType]);

  useEffect(() => {
    const matchedMenuIds = searchQuery
      ? filteredMenu
          .filter((menuItem) => menuItem.reports.length > 0)
          .map((menuItem) => menuItem.menu_id)
      : [];

    setSearchOpenMenus(matchedMenuIds);
  }, [searchQuery, filteredMenu]);

  const handleToggle = (menuId) => {
    if (searchQuery) {
      if (searchOpenMenus.includes(menuId)) {
        setSearchOpenMenus(searchOpenMenus.filter((id) => id !== menuId));
      } else {
        setSearchOpenMenus([...searchOpenMenus, menuId]);
      }
    } else {
      if (openMenus.includes(menuId)) {
        setOpenMenus(openMenus.filter((id) => id !== menuId));
      } else {
        setOpenMenus([...openMenus, menuId]);
      }
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const resetFilters = () => {
    setFilter("all");
    setMenuType("all");
    setSearchQuery("");
  };

  const combinedOpenMenus = searchQuery ? searchOpenMenus : openMenus;

  const handleExpandCollapseAll = () => {
    if (allExpanded) {
      setOpenMenus([]);
      setSearchOpenMenus([]);
    } else {
      setOpenMenus(sortedMenu.map((menuItem) => menuItem.menu_id));
      setSearchOpenMenus(filteredMenu.map((menuItem) => menuItem.menu_id));
    }
    setAllExpanded(!allExpanded);
  };

  return (
    <div className="ReportListContainer">
      <div className="allReportContainer">
        <h1 className="reportListHeaderTitle">Master Report List</h1>
        <div className="reportListHeader">
          <InputGroup className="mb-3" style={{ width: "85%" }}>
            <InputGroup.Text id="search-addon">
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              placeholder="Search Reports and More Info"
              aria-label="Search reports"
              aria-describedby="search-addon"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <InputGroup.Text
                id="clear-search-addon"
                onClick={handleClearSearch}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimes} title="Clear Search" />
              </InputGroup.Text>
            )}
            <Button variant="primary" onClick={() => setShowFilterModal(true)}>
              <FontAwesomeIcon icon={faFilter} />
            </Button>
            <Button
              variant="secondary"
              onClick={handleExpandCollapseAll}
              style={{
                margin: "0 10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={allExpanded ? faCompressArrowsAlt : faExpandArrowsAlt}
                title={allExpanded ? " Collapse All" : " Expand All"}
              />
              {/* {allExpanded ? " Collapse All" : " Expand All"} */}
            </Button>
          </InputGroup>
        </div>

        <FilterModal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          filter={filter}
          setFilter={setFilter}
          menuType={menuType}
          setMenuType={setMenuType}
          resetFilters={resetFilters}
        />

        <div
          className={`reportGrid ${
            combinedOpenMenus.length > 0 ? "expandedGrid" : ""
          }`}
        >
          {filteredMenu.map(
            (menuItem) =>
              menuItem.reports.length > 0 && (
                <Card
                  key={menuItem.menu_id}
                  className={`mb-3 reportCard ${
                    combinedOpenMenus.includes(menuItem.menu_id)
                      ? "expanded"
                      : ""
                  }`}
                >
                  <Card.Header>
                    <Button
                      onClick={() => handleToggle(menuItem.menu_id)}
                      className="menuItemBtn"
                      title={
                        combinedOpenMenus.includes(menuItem.menu_id)
                          ? "Collapse"
                          : "Expand"
                      }
                    >
                      {menuItem.title}
                    </Button>
                  </Card.Header>
                  {combinedOpenMenus.includes(menuItem.menu_id) && (
                    <Card.Body>
                      {menuItem.notes && (
                        <div
                          className="notesText"
                          dangerouslySetInnerHTML={{
                            __html: highlightText(menuItem.notes, searchQuery),
                          }}
                        ></div>
                      )}
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Report Title</th>
                            <th>More Info</th>
                            <th>Record Types</th>
                            <th>Published</th>
                          </tr>
                        </thead>
                        <tbody>
                          {menuItem.reports.map((report) => (
                            <tr
                              key={report.report_id}
                              style={{
                                backgroundColor:
                                  report.publish !== 1
                                    ? "#f8d7da"
                                    : "transparent",
                              }}
                            >
                              <td
                                style={{
                                  color: report.publish !== 1 ? "black" : "",
                                  fontWeight:
                                    report.publish !== 1 ? "bold" : "normal",
                                  backgroundColor:
                                    report.publish !== 1
                                      ? "#f8d7da"
                                      : "transparent",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(
                                    menuItem.menu_type === "dashboard"
                                      ? report.title
                                      : report.menu_title,
                                    searchQuery
                                  ),
                                }}
                              ></td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(
                                    report.more_info,
                                    searchQuery
                                  ),
                                }}
                              ></td>
                              <td>{getUniqueBadges(report)}</td>
                              <td>{report.publish === 1 ? "Yes" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  )}
                </Card>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportList;
