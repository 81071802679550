import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const NotesModal = ({
  show,
  handleClose,
  selectedNote,
  saveNote,
  menuItems,
  selectedMenu, // This should be passed correctly from the parent
}) => {
  const [note, setNote] = useState({
    menu_title: "",
    report_title: "",
    release_note: "",
    sort_by: "",
  });

  useEffect(() => {
    if (selectedNote) {
      setNote({
        menu_title: selectedNote.menu_title || selectedMenu?.title || "",
        report_title: selectedNote.report_title || "",
        release_note: selectedNote.release_note || "",
        sort_by: selectedNote.sort_by || "",
      });
    }
  }, [selectedNote, selectedMenu]);

  const handleChange = (field, value) => {
    setNote({
      ...note,
      [field]: value,
    });

    if (field === "menu_title") {
      // Automatically set report_title when menu_title changes
      const firstReport = menuItems
        .filter((menu) => menu.title === value)
        .flatMap((menu) => menu.reports)[0]?.title || "";

      setNote((prevNote) => ({
        ...prevNote,
        report_title: firstReport || "", // Set the first report as the selected one or leave blank
      }));
    }
  };

  const filteredReports = menuItems
    .filter((menu) => menu.title === note.menu_title)
    .flatMap((menu) => menu.reports || []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedNote ? "Edit Note" : "New Note"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="noteMenu">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              value={note.menu_title}
              onChange={(e) => handleChange("menu_title", e.target.value)}
            >
              <option value="">[Blank]</option>
              {menuItems.map((menu, index) => (
                <option key={index} value={menu.title}>
                  {menu.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="noteReport">
            <Form.Label>Check/Merge Name</Form.Label>
            <Form.Control
              as="select"
              value={note.report_title}
              onChange={(e) => handleChange("report_title", e.target.value)}
              disabled={!note.menu_title} // Disable if no menu_title is selected
            >
              <option value="">[Blank]</option>
              {filteredReports.map((report, index) => (
                <option key={index} value={report.title}>
                  {report.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="noteReleaseNote">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={note.release_note}
              onChange={(e) => handleChange("release_note", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="noteSortBy">
            <Form.Label>Order</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={note.sort_by}
              onChange={(e) => handleChange("sort_by", e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            saveNote({
              ...selectedNote,
              ...note,
            })
          }
        >
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotesModal;
