import React from "react";
import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NonDashboardReports = ({
  viewReports,
  handleBackToMenu,
  handleEditReport,
  handleTogglePublish,
  displayIcon,
  handleDeleteReport,
}) => {
  return (
    <div className="reports-table-container">
      <h2>{viewReports.title} Reports</h2>
      <Button variant="secondary" onClick={handleBackToMenu}>
        Back to Menu
      </Button>
      <Table striped bordered hover className="reports-table" id="reportsTable">
        <thead className="reportsHeaders">
          <tr>
            <th>Icon</th>
            <th>Report Menu Title (Shown on Nav)</th>
            <th>Report Title (Shown on the Report)</th>
            <th>More Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {viewReports.reports?.map((report, index) => (
            <tr key={index} className="pointer">
              <td>{displayIcon(report.icon)}</td>
              <td
                className="pointer"
                onClick={() => handleEditReport(report)}
              >
                {report.menu_title}
              </td>
              <td
                className="pointer"
                onClick={() => handleEditReport(report)}
              >
                {report.title}
              </td>
              <td>
                {report.more_info && report.more_info.length > 25
                  ? `${report.more_info.substring(0, 25)}...`
                  : report.more_info || ""}
              </td>
              <td className="nowrap">
                <Button
                  variant="info"
                  size="sm"
                  title="Edit Report"
                  onClick={() => handleEditReport(report)}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
                <Button
                  variant={report.publish ? "primary" : "danger"}
                  size="sm"
                  onClick={() => handleTogglePublish(report, !report.publish)}
                  title={report.publish ? "Click To Unpublish Report" : "Click To Publish Report"}
                >
                  <FontAwesomeIcon
                    icon={report.publish ? "eye" : "eye-slash"}
                  />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  title="Delete Report"
                  onClick={() => handleDeleteReport(report)}
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </td>
            </tr>
          )) || (
            <tr>
              <td colSpan="5" className="text-center">
                No reports available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default NonDashboardReports;
