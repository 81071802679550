import React from "react";
import { Badge } from "react-bootstrap";

function ReportBadge({ bg, message }) {
  const badgeStyle = {
    marginLeft: "8px",
    padding: "4px 5px",
    fontSize: "0.75em",
    borderRadius: "20px",
    display: "inline-block",
    textAlign: "center",
    minWidth: "20px",
    color: "#fff",
  };

  return (
    <Badge bg={bg} className="report-badge" style={badgeStyle}>
      {message}
    </Badge>
  );
}

export default ReportBadge;
