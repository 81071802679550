import React, { useContext, useState, useCallback } from "react";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Spinner } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ErrorModal from "../ErrorModal/ErrorModal";

// Utility function to sanitize filenames
const sanitizeFilename = (filename) => {
  return filename
    .replace(/[^a-z0-9 ]/gi, "") // Remove non-alphanumeric characters except spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

// Utility function to format date strings
const formatDateString = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString;
  }
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

// Function to generate formatted timestamps
const getFormattedTimestamp = () => {
  const today = new Date();
  return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(today.getDate()).padStart(2, "0")}`;
};

// Generate CSV content from report data
const generateCSVContent = (data) => {
  const cr = "\r\n";
  let csvContent = data.headers + cr;
  data.data.forEach((row) => {
    const formattedRow = row.row
      .split(",")
      .map((cell) =>
        cell.match(/\d{4}-\d{2}-\d{2}/) ? formatDateString(cell) : cell
      )
      .join(",");
    csvContent += formattedRow + cr;
  });
  return csvContent;
};

const DownloadAllDash = ({ show, onHide, menuItem }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  const [totalReports, setTotalReports] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  const [showNoRecordsModal, setShowNoRecordsModal] = useState(false); // State for no records modal
  const { reports, selectedYear } = useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();

  // Function to reset the download states
  const resetExportState = () => {
    setIsDownloading(false);
    setCurrentReportIndex(0);
    setTotalReports(0);
    setShowErrorModal(false);
    setErrorDetails("");
    setShowNoRecordsModal(false); // Reset no records modal state
  };

  // Toggle modal visibility and reset states if closing
  const toggleModal = useCallback(() => {
    if (!show) {
      resetExportState();
    }
    onHide();
  }, [show, onHide]);

  // Handle the download process
  const downloadAllReportsAsZip = async () => {
    setIsDownloading(true);

    try {
      const zip = new JSZip();
      const childReports = menuItem.reports.filter(
        (report) => report.child_report?.report_id
      );

      const validReports = [];
      for (const report of childReports) {
        const reportId = report.child_report.report_id;
        try {
          const response = await axios.post(
            `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
          );

          if (response.data.data.length > 0) {
            validReports.push(report);
          }
        } catch (error) {
          console.error(`Error fetching report ${reportId}: ${error.message}`);
        }
      }

      const total = validReports.length;
      setTotalReports(total);

      if (total === 0) {
        setIsDownloading(false);
        setShowNoRecordsModal(true); // Show modal if no records to download
        return;
      }

      for (const [index, report] of validReports.entries()) {
        const reportId = report.child_report.report_id;
        setCurrentReportIndex(index + 1);

        const response = await axios.post(
          `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
        );

        const csvContent = generateCSVContent(response.data);
        const sanitizedTitle = sanitizeFilename(reports[reportId].report.title);
        zip.file(`${sanitizedTitle}.csv`, csvContent);
      }

      const timestamp = getFormattedTimestamp();
      const districtName = selectedDistrict
        ? sanitizeFilename(selectedDistrict.irn)
        : "all";
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${menuItem.title}_${districtName}_${timestamp}.zip`);

      toggleModal(); // Close modal when all API calls are complete
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setIsDownloading(false);
      setErrorDetails(error.message || "An unknown error occurred");
      setShowErrorModal(true);
    }
  };

  const handleErrorModalClose = () => setShowErrorModal(false);
  const handleNoRecordsModalClose = () => setShowNoRecordsModal(false); // Close no records modal

  return (
    <>
      <Button
        variant="primary"
        onClick={toggleModal}
        className="extractBtn"
        title="Download All Reports"
      >
        <FontAwesomeIcon icon={faFileExport} />
      </Button>
      <Modal show={show} onHide={toggleModal} className="exportModal">
        <Modal.Header closeButton>
          <Modal.Title>Download All Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to download all dashboard reports for{" "}
            {menuItem.title}?
          </p>
          <p>
            This action will download all reports as individual CSV files within
            a single ZIP file. Please note that this process may take a few
            minutes depending on the file sizes and number of reports.
          </p>
          <p>
            <strong>
              Please note: reports without current data will not be downloaded.
            </strong>
          </p>
          {isDownloading && (
            <div className="loading-container">
              <Spinner animation="border" size="sm" style={{ color: "blue" }} />
              <span>{` Downloading ${currentReportIndex} of ${totalReports}`}</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={downloadAllReportsAsZip}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner animation="border" size="sm" className="blue-spinner" />
            ) : (
              "Download"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal
        show={showErrorModal}
        onHide={handleErrorModalClose}
        errorCode={500}
        errorMessage={`Failed to download the following reports: ${errorDetails}`}
      />

      {/* Modal to show when no records are available to download */}
      <Modal show={showNoRecordsModal} onHide={handleNoRecordsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>No Records Available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            There are no records available to download for {menuItem.title}.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoRecordsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadAllDash;
