import React, { createContext, useState, useCallback } from "react";
import axios from "../api/axios";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [menu, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const getData = useCallback(async (menuId = false, reportId = false) => {
    try {
      setLoading(true);
      const [menuResponse, sourcesResponse] = await Promise.all([
        axios.get("/cc/menu"),
        axios.get("/cc/dataset/list"),
      ]);

      setMenu(menuResponse.data);
      setSources(sourcesResponse.data);

      if (menuId || reportId) {
        let selectedMenuItem = null;
        let selectedReportItem = null;

        menuResponse.data.forEach((menuItem) => {
          if (menuItem.menu_id === menuId) {
            selectedMenuItem = menuItem;
            if (reportId) {
              menuItem.reports?.forEach((reportItem) => {
                if (reportItem.report_id === reportId) {
                  selectedReportItem = reportItem;
                }
              });
            }
          }
        });

        if (selectedMenuItem) {
          setSelectedMenu(selectedMenuItem);
          if (selectedReportItem) {
            setSelectedReport(selectedReportItem);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error loading data:", error);
      setLoading(false);
    }
  }, []);

  const saveOrEditReport = async (report) => {
    try {
      report.menu_id = report.menu_id || selectedMenu?.menu_id;

      if (report.sort_by === "") report.sort_by = null;

      if (report.report_id) {
        await axios.put(`/cc/report/${report.report_id}`, report);
      } else {
        await axios.post("/cc/report", report);
      }

      setSelectedReport(null);
      getData(selectedMenu?.menu_id);
    } catch (error) {
      console.error("Error saving or editing report:", error);
    }
  };

  const saveMenu = async (menu) => {
    try {
      const menuCopy = { ...menu };
      delete menuCopy.$$hashKey;
      delete menuCopy.loading;
      delete menuCopy.confirmDelete;
      delete menuCopy.reports;

      if (menu.menu_id) {
        await axios.put(`/cc/menu/${menu.menu_id}`, menuCopy);
      } else {
        await axios.post("/cc/menu", menuCopy);
      }
      setSelectedMenu(null);
      getData();
    } catch (error) {
      console.error("Error saving menu:", error);
    }
  };

  const deleteMenu = async (menu) => {
    try {
      const menuCopy = { ...menu };
      delete menuCopy.$$hashKey;
      delete menuCopy.loading;
      delete menuCopy.confirmDelete;
      delete menuCopy.reports;
      menuCopy.deleted = true;

      if (menu.menu_id) {
        await axios.put(`/cc/menu/${menu.menu_id}`, menuCopy);
      }
      setSelectedMenu(null);
      getData();
    } catch (error) {
      console.error("Error deleting menu:", error);
    }
  };

  const publishReport = async (report, publish) => {
    try {
      await axios.put(
        `/cc/report/${publish ? "publish" : "unpublish"}/${report.report_id}`
      );
      setMenu((prevMenu) =>
        prevMenu.map((menuItem) =>
          menuItem.menu_id ===
          (selectedMenu ? selectedMenu.menu_id : menuItem.menu_id)
            ? {
                ...menuItem,
                reports: menuItem.reports.map((rep) =>
                  rep.report_id === report.report_id ? { ...rep, publish } : rep
                ),
              }
            : menuItem
        )
      );
    } catch (error) {
      console.error("Error publishing report:", error);
    }
  };

  const deleteReport = async (report) => {
    try {
      await axios.delete(`/cc/report/${report.report_id}`);
      getData(selectedMenu?.menu_id);
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  const saveColumn = async (column) => {
    try {
        // Ensure the column has the correct report_id associated with it
        if (!column.report_id && selectedReport?.report_id) {
            column.report_id = selectedReport.report_id;
        }

        // Additional safeguard: Log to ensure report_id is correct before proceeding
        if (!column.report_id) {
            console.error("No report_id found for the column:", column);
            return;
        }

        column.is_pk = column.is_pk_tf ? 1 : 0;
        column.export_only = column.export_only_tf ? 1 : 0;

        if (column.columns_id) {
            await axios.put(`/cc/columns/${column.columns_id}`, column);
        } else {
            await axios.post("/cc/columns", column);
        }

        // Fetch the updated report immediately after saving the column to ensure the state is in sync
        const updatedReport = await axios.get(`/cc/report/admin/${column.report_id}`);
        setSelectedReport(updatedReport.data);

        // Save the report if needed, ensuring we have the updated report data
        if (updatedReport.data.report_id) {
            await axios.put(`/cc/report/${updatedReport.data.report_id}`, updatedReport.data);
            getData(selectedMenu?.menu_id, updatedReport.data.report_id);
        } else {
            console.error("No report_id found after updating the report.");
        }
    } catch (error) {
        console.error("Error saving column and report:", error);
    }
};

  const deleteColumn = async (column) => {
    try {
      await axios.delete(`/cc/columns/${column.columns_id}`);
      getData(selectedMenu?.menu_id, selectedReport?.report_id);
    } catch (error) {
      console.error("Error deleting column:", error);
    }
  };

  const saveNote = async (note) => {
    try {
      if (note.note_id) {
        await axios.put(`/cc/note/${note.note_id}`, note);
      } else {
        note.report_id = selectedReport?.report_id;
        await axios.post("/cc/note", note);
      }
      getData(selectedMenu?.menu_id, selectedReport?.report_id);
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const deleteNote = async (note) => {
    try {
      await axios.delete(`/cc/note/${note.note_id}`);
      getData(selectedMenu?.menu_id, selectedReport?.report_id);
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        menu,
        selectedMenu,
        selectedReport,
        sources,
        loading,
        confirmDelete,
        setConfirmDelete,
        setSelectedMenu,
        setSelectedReport,
        getData,
        saveMenu,
        deleteMenu,
        publishReport,
        saveOrEditReport,
        deleteReport,
        saveColumn,
        deleteColumn,
        saveNote,
        deleteNote,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};