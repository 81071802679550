import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const PrintReleaseNotes = ({ releaseNotesTable, reportTitle }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      {/* Add style to hide the button during print */}
      <style>{`
        @media print {
          .print-button {
            display: none;
          }
        }
      `}</style>

      <div ref={componentRef}>
        {/* Print the report title */}
        <h3>{reportTitle}</h3>
        <Button onClick={handlePrint} className="mt-3 print-button" title="Print Release Note">
          <FontAwesomeIcon icon={faPrint} />
        </Button>

        {/* Print the release notes table */}
        {releaseNotesTable}
      </div>
    </div>
  );
};

export default PrintReleaseNotes;
