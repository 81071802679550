import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

const ColumnModal = ({ show, handleClose, selectedColumn, saveColumn }) => {
  const [column, setColumn] = useState({
    ...selectedColumn,
    is_pk_tf: selectedColumn?.is_pk === 1,
    export_only_tf: selectedColumn?.export_only === 1,
  });

  useEffect(() => {
    setColumn({
      ...selectedColumn,
      is_pk_tf: selectedColumn?.is_pk === 1,
      export_only_tf: selectedColumn?.export_only === 1,
    });
  }, [selectedColumn]);

  const toggleCheckbox = (name) => {
    setColumn((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleSave = () => {
    const updatedColumn = {
      ...column,
      is_pk: column.is_pk_tf ? 1 : 0,
      export_only: column.export_only_tf ? 1 : 0,
    };
    saveColumn(updatedColumn);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{column?.columns_id ? "Edit" : "New"} Column</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              <strong>Badge/File</strong>
            </Form.Label>
            <Form.Control
              type="text"
              name="badge"
              value={column?.badge || ""}
              onChange={(e) => setColumn({ ...column, badge: e.target.value })}
              placeholder=""
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>Title</strong>
            </Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={column?.title || ""}
              onChange={(e) => setColumn({ ...column, title: e.target.value })}
              placeholder=""
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>Data Type</strong>
            </Form.Label>
            <Form.Control
              as="select"
              name="data_type"
              value={column?.data_type || ""}
              onChange={(e) =>
                setColumn({ ...column, data_type: e.target.value })
              }
            >
              <option value="">Default</option>
              <option value="date">date (Mon DD, YYYY)</option>
              <option value="date:MM/dd/yyyy">date (MM/DD/YYYY)</option>
              <option value="number">Number</option>
              <option value="number:2">Number 2 decimals</option>
              <option value="number:3">Number 3 decimals</option>
              <option value="lowercase">lower case</option>
              <option value="uppercase">UPPER CASE</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>Order</strong>
            </Form.Label>
            <Form.Control
              type="number"
              min="1"
              name="sort_by"
              value={column?.sort_by || ""}
              onChange={(e) =>
                setColumn({ ...column, sort_by: e.target.value })
              }
              placeholder=""
            />
          </Form.Group>
          <Form.Group>
            <div
              className="columnBoxes"
              onClick={() => toggleCheckbox("is_pk_tf")}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={column.is_pk_tf ? faCheckSquare : faSquare}
                className="me-2-column"
              />
              <strong>Primary Key</strong>
            </div>
            <Form.Text className="text-muted" id="primaryKeyHelp">
              Identify column(s) that would make a unique record, used for
              Review.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <div
              className="columnBoxes"
              onClick={() => toggleCheckbox("export_only_tf")}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={column.export_only_tf ? faCheckSquare : faSquare}
                className="me-2-column"
              />
              <strong>Export Only</strong>
            </div>
            <Form.Text className="text-muted" id="exportOnlyHelp">
              Only display column on the Export File.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ColumnModal;
