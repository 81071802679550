import React, { createContext, useState, useEffect } from "react";
import axios from "../api/axios";
import { useDistrict } from "../components/Breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import UpdatePassword from "../components/UpdatePassword/UpdatePassword";
import Maintenance from "../components/Maintenance/Maintenance";
import { adminRoles, checkRole } from "../components/AdminRoles/AdminRoles";
import ErrorModal from "../components/ErrorModal/ErrorModal";

export const SyncReportsContext = createContext();

export const SyncReportsProvider = ({ children }) => {
  const [childReports, setChildReports] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] =
    useState(false);
  const [isYearLoading, setIsYearLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);
  const [fullMenu, setFullMenu] = useState([]);
  const [prevReports, setPrevReports] = useState({});
  const [records, setRecords] = useState([]);
  const [reports, setReports] = useState({});
  const [reviewed, setReviewed] = useState({});
  const [reviews, setReviews] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserForUpdate, setSelectedUserForUpdate] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  //  eslint-disable-next-line
  const [showMaintenance, setShowMaintenance] = useState(false);
  const [sortedReports, setSortedReports] = useState({});
  const [syncStatus, setSyncStatus] = useState("syncing");
  const [tableData, setTableData] = useState({});
  const [userAd, setUserAd] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [users, setUsers] = useState([]);
  const [crosscheckAdmin, setCrosscheckAdmin] = useState(false);
  const [isItcAdmin, setIsItcAdmin] = useState(false);
  const [itcSifAdministrator, setItcSifAdministrator] = useState(false);
  const [reviewer, setReviewer] = useState(false);
  const [submitter, setSubmitter] = useState(false);
  const [isModalClosedManually, setIsModalClosedManually] = useState(false);

  const { selectedDistrict, admin, setAdmin } = useDistrict();
  const navigate = useNavigate();
  // New Error Modal
  const [errorModal, setErrorModal] = useState({
    show: false,
    errorCode: null,
    errorMessage: null,
  });

  useEffect(() => {
    const initialize = async () => {
      if (selectedDistrict) {
        setIsYearLoading(true);
        resetStates();
        await getCurrentYear();
        await fetchSyncData();
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict, showMaintenance]);

  useEffect(() => {
    if (selectedYear) {
      fetchSyncData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const resetStates = () => {
    setMenu([]);
    setReports({});
    setChildReports([]);
    setTableData({});
    setPrevReports({});
    setReviews({});
    setSortedReports({});
    setSelectedUser(null);
  };

  const getCurrentYear = async () => {
    try {
      const response = await axios.get("/get_current_year");
      setSelectedYear(response.data);
    } catch (error) {
      showErrorModal(
        error.response?.status || 500,
        "Failed to fetch the current year."
      );
      throw error;
    } finally {
      setIsYearLoading(false);
    }
  };

  const fetchSyncData = async () => {
    if (isYearLoading) return;
    setLoading(true);
    setSyncStatus("syncing");

    if (showMaintenance) {
      setLoading(false);
      setSyncStatus("maintenance");
      return;
    }

    try {
      const records = await syncDistrictData(selectedDistrict.irn);
      setRecords(records);
      setSyncStatus("success");
      await initDistricts();
    } catch (error) {
      if (error.response?.status === 500) {
        // Retry logic for 500 errors
        console.warn("Retrying sync due to server error");
        try {
          const records = await syncDistrictData(selectedDistrict.irn);
          setRecords(records);
          setSyncStatus("success");
          await initDistricts();
        } catch (retryError) {
          showErrorModal(
            retryError.response?.status || 500,
            "Failed to sync district data after retry."
          );
          setSyncStatus("error");
        }
      } else {
        showErrorModal(
          error.response?.status || 500,
          "Failed to sync district data."
        );
        setSyncStatus("error");
      }
    } finally {
      setLoading(false);
      if (isModalClosedManually) {
        setIsModalClosedManually(false); // Reset manual close flag once loading is complete
      }
    }
  };

  const syncDistrictData = async (districtId) => {
    try {
      const response = await axios.post(`/cc/sync/${districtId}`);
      return response.data.records;
    } catch (error) {
      console.error("Error syncing district data: ", error);
      throw error;
    }
  };

  const showErrorModal = (errorCode, errorMessage) => {
    setErrorModal({ show: true, errorCode, errorMessage });
  };

  const hideErrorModal = () => {
    setErrorModal({ show: false, errorCode: null, errorMessage: null });
  };

  const initDistricts = async () => {
    try {
      const [sources, roles, { users, ad, roles: userRoles }] =
        await fetchDataForDistrict(selectedDistrict.irn);

      const normalizedUserRoles = userRoles.map((role) => role.toLowerCase());
      const isItcAdmin = checkRole(normalizedUserRoles, adminRoles);

      setDataSources(sources);
      setUserRole(roles);
      setUsers(users);
      setUserAd(ad);
      setItcSifAdministrator(isItcAdmin);
      setAdmin(admin);

      setCrosscheckAdmin(normalizedUserRoles.includes("crosscheckadmin"));
      setSubmitter(checkRole(normalizedUserRoles, ["submitter", "submit"]));
      setReviewer(checkRole(normalizedUserRoles, ["reviewer", "review"]));

      // Show maintenance modal after verifying roles
      // Show if the user is not an ITC Admin and not a crosscheckadmin
      // if (!normalizedUserRoles.includes("crosscheckadmin") && !isItcAdmin) {
      //   setShowMaintenance(true);
      //   return; // Stop further execution
      // } else {
      //   setShowMaintenance(false);
      // }

      if (!ad && users.length > 0) {
        const expirationDate = new Date(users[0].password_expiration);
        if (expirationDate <= new Date()) {
          setIsUpdatePasswordModalOpen(true);
          return;
        }
      }

      await populateReports();
    } catch (error) {
      console.error("Error initializing districts: ", error);
    }
  };

  const fetchDataForDistrict = async (districtId) => {
    const urls = [
      `/cc/dataset/district/${districtId}`,
      `/cc/check_role/${districtId}`,
      `/cc/users/${districtId}`,
    ];
    const responses = await Promise.all(urls.map((url) => axios.get(url)));
    return responses.map((res) => res.data);
  };

  const populateReports = async () => {
    const endpoint = admin ? `/cc/menu/all` : `/cc/menu`;
    try {
      const sortedMenuData = await fetchMenuData(endpoint);
      await triggerReports(sortedMenuData);
    } catch (error) {
      showErrorModal(
        error.response?.status || 500,
        "Failed to initialize reports. Please try again or contact support."
      );
    }
  };

  const fetchMenuData = async (endpoint) => {
    try {
      const response = await axios.get(endpoint);
      return sortMenuData(response.data);
    } catch (error) {
      showErrorModal(
        error.response?.status || 500,
        "Failed to fetch menu data."
      );
      if (error.response && [403, 401].includes(error.response.status)) {
        console.error(
          "Authorization error with menu data fetch, trying fallback endpoint."
        );
        return fetchMenuData("/cc/menu"); // Retrying with fallback endpoint
      }
      throw error;
    }
  };

  const sortMenuData = (menuData) => {
    return [...menuData].sort(
      (a, b) => a.sort_by - b.sort_by || a.title.localeCompare(b.title)
    );
  };

  const triggerReports = async (menuData) => {
    setMenu(menuData);
    await triggerReportsRun(menuData);
  };

  const triggerReportsRun = async (menuData) => {
    const reportProcessingTasks = menuData.flatMap((menuItem) => {
      if (menuItem.menu_type === "notes") {
        return [];
      }
      return Array.isArray(menuItem.reports)
        ? menuItem.reports.map((report) =>
            runReport(report).catch((error) =>
              console.error(`Failed to run report ${report.report_id}: `, error)
            )
          )
        : [];
    });

    await Promise.all(reportProcessingTasks);
  };

  const runReport = async (report) => {
    if (!selectedYear) {
      showErrorModal(400, "Selected year is not set");
      return;
    }

    updateReportState(report.report_id, { loading: true });

    try {
      const response = await axios.post(
        `/cc/run/${selectedDistrict.irn}/report/${report.report_id}/year/${selectedYear}`
      );
      updateReportState(report.report_id, { ...response.data, loading: false });
      setReviews((prevReviews) => ({
        ...prevReviews,
        [report.report_id]: response.data,
      }));
    } catch (error) {
      updateReportState(report.report_id, {
        loading: false,
        error: error.response?.data || "An error occurred",
      });
    }
  };

  const updateReportState = (reportId, newData) => {
    setReports((prevReports) => ({
      ...prevReports,
      [reportId]: { ...prevReports[reportId], ...newData },
    }));
  };

  const checkForPasswordUpdate = (users) => {
    const userNeedingUpdate = users.find((user) => {
      const expirationDate = new Date(user.password_expiration);
      return expirationDate <= new Date();
    });
    if (userNeedingUpdate) {
      openUpdatePasswordModal(userNeedingUpdate);
    }
  };

  const selectUser = (user) => {
    const selectedUserCopy = JSON.parse(JSON.stringify(user));
    selectedUserCopy.reportList = JSON.parse(JSON.stringify(menu));

    selectedUserCopy.reportList.forEach((category) => {
      category.reports.forEach((report) => {
        const foundReport = selectedUserCopy.reports.find(
          (element) => element.report_id === report.report_id
        );
        if (foundReport) {
          report.selected = foundReport.access;

          if (report.child_report) {
            const foundChild = category.reports.find(
              (element) => element.report_id === report.child_report.report_id
            );
            if (foundChild) {
              report.child_report = foundChild;
            }
          }
        }
      });
    });

    setSelectedUser(selectedUserCopy);
  };

  const findReportById = (reportId) => {
    if (!menu || !Array.isArray(menu)) {
      console.error("Menu is not an array:", menu);
      return null;
    }
    for (let menuItem of menu) {
      if (Array.isArray(menuItem.reports)) {
        const found = menuItem.reports.find(
          (report) => report.report_id === reportId
        );
        if (found) return found;
      }
    }
    return null;
  };

  const openUpdatePasswordModal = (user) => {
    setSelectedUserForUpdate(user);
    setIsUpdatePasswordModalOpen(true);
  };

  const closeUpdatePasswordModal = () => {
    setSelectedUserForUpdate(null);
    setIsUpdatePasswordModalOpen(false);
  };

  const handleModalClose = () => {
    setIsUpdatePasswordModalOpen(false);
    setSelectedUserForUpdate(null);
    navigate("/logout");
  };

  const hasAccess = (report) => {
    const foundReport = selectedUser.reports.find(
      (element) => element.report_id === report.report_id
    );
    return foundReport && foundReport.access;
  };

  const toReview = (test) => {
    if (!test) return -1;
    try {
      if (!test.found_pk) return test.count;
      return test.data.filter((v) => !v.reviewed.reviewed).length;
    } catch {
      return 0;
    }
  };

  const treeToReview = (menu) => {
    if (!reports || reports.length === 0) return null;
    if (!menu.reports || menu.reports.length === 0) return null;

    let totalReports = 0,
      err = false;

    for (let reportIdx = 0; reportIdx < menu.reports.length; reportIdx++) {
      try {
        if (reports[menu.reports[reportIdx].report_id]) {
          if (reports[menu.reports[reportIdx].report_id].error) err = true;
          if (reports[menu.reports[reportIdx].report_id].found_pk) {
            let test = reports[menu.reports[reportIdx].report_id].data;
            totalReports += test.filter((v) => !v.reviewed.reviewed).length;
          }
        }
      } catch {
        return null;
      }
    }

    if (totalReports > 500) totalReports = "500+";
    if (err) {
      if (totalReports === 0) return "err";
      totalReports += " & err";
    }

    return totalReports;
  };

  const hasReview = (test) => {
    if (!test) return false;
    try {
      return test.data.filter((v) => v.reviewed.reviewed).length;
    } catch {
      return 0;
    }
  };

  const clearSelectedUser = () => {
    setSelectedUser(null);
  };

  const refreshData = async () => {
    await getCurrentYear();
    await fetchSyncData();
    setIsModalClosedManually(false);
  };

  // In the background run cc/menu/all to get the full menu for all AD users
  useEffect(() => {
    const fetchFullMenu = async () => {
      // if AD
      if (userAd) {
        try {
          const response = await axios.get("/cc/menu/all");
          setFullMenu(response.data);
          console.log("Full Menu: ", response.data);
        } catch (error) {
          console.error("Error fetching full menu: ", error);
        }
      }
    };
    fetchFullMenu();
  }, [userAd]);

  const contextValue = {
    checkForPasswordUpdate,
    childReports,
    clearSelectedUser,
    closeUpdatePasswordModal,
    crosscheckAdmin,
    dataSources,
    fetchSyncData,
    findReportById,
    hasAccess,
    hasReview,
    initDistricts,
    isItcAdmin,
    isLoading,
    itcSifAdministrator,
    loading,
    menu,
    openUpdatePasswordModal,
    prevReports,
    records,
    refreshData,
    reportData: reports,
    reportGroups: menu,
    reports,
    reviewed,
    reviewer,
    reviews,
    runReport,
    selectUser,
    selectedUser,
    selectedYear,
    setChildReports,
    setIsItcAdmin,
    setLoading,
    setPrevReports,
    setReports,
    setReviewed,
    setReviews,
    setSelectedYear,
    setSortedReports,
    setTableData,
    sortedReports,
    submitter,
    syncStatus,
    tableData,
    toReview,
    treeToReview,
    updatedReport: reports,
    userAd,
    userRole,
    users,
    showErrorModal,
    fullMenu,
    isModalClosedManually,
    setIsModalClosedManually,
  };

  return (
    <SyncReportsContext.Provider value={contextValue}>
      {children}
      {showMaintenance && <Maintenance show={showMaintenance} />}
      {isUpdatePasswordModalOpen && (
        <UpdatePassword
          show={isUpdatePasswordModalOpen}
          handleClose={handleModalClose}
          user={selectedUserForUpdate}
        />
      )}
      <ErrorModal
        show={errorModal.show}
        onHide={hideErrorModal}
        errorCode={errorModal.errorCode}
        errorMessage={errorModal.errorMessage}
        title="Network Error" // Optional custom title
      />
    </SyncReportsContext.Provider>
  );
};

export default SyncReportsProvider;
