import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import "./ReportLegend.css";

function ReportLegend() {
  const { dataSources, reports } = useContext(SyncReportsContext);
  const { reportId } = useParams(); 
  const [reportDataSources, setReportDataSources] = useState([]);

  useEffect(() => {
    const updateDataSources = () => {
      const report = reports[reportId];
      if (report && report.sources) {
        const relevantSources = report.sources
          .filter(source => dataSources[source])
          .map(source => ({
            name: source,
            ...dataSources[source],
          }));
        setReportDataSources(relevantSources);
      }
    };

    updateDataSources();
  }, [reportId, reports, dataSources]);

  const formatDate = (dateString) => new Date(dateString).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <fieldset className="report-legend">
      <legend className="legend-title">Legend</legend>
      <div className="sourceBadges">
        {reportDataSources.map((source, index) => (
          source.description && source.collection_date && (
            <div className="badge-with-text" key={index}>
              <Badge bg="dark">{source.name}</Badge>
              <span className="badge-text"> - {source.description}
                <em> ({formatDate(source.collection_date)})</em>
              </span>
            </div>
          )
        ))}
        <div className="badge-with-text">
          <Badge bg="success"><FontAwesomeIcon icon={faCheck} /></Badge>
          <span className="badge-text"> - Record has been reviewed</span>
        </div>
        <div className="badge-with-text">
          <Badge bg="warning"><FontAwesomeIcon icon={faTriangleExclamation} /></Badge>
          <span className="badge-text"> - Record has not been reviewed</span>
        </div>
      </div>
    </fieldset>
  );
}

export default ReportLegend;

