import React, { useState, useEffect } from "react";
import "./Chat.css"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faAppleWhole } from "@fortawesome/free-solid-svg-icons";

const Chat = () => {
  const [username, setUsername] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [responses, setResponses] = useState([]);
  
  useEffect(() => {
    const savedDisplayName = localStorage.getItem("display_name");
    if (savedDisplayName) {
      setUsername(savedDisplayName);
    }
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    // Clear the input and all responses
    setSearchQuery("");
    setResponses([]);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    // Track the user's question and the bot's responses
    const newResponses = [
      "CCBot: I’m here to help you with your enrollment scenarios.",
      "CCBot: Please provide more details about your question.",
      "CCBot: I'm sorry, but I cannot assist with that.",
      "CCBot: Please try asking your question in a different way.",
      "CCBot: I'm sorry, I don't understand. Can you rephrase your question?",
      "CCBot: I'm sorry, I cannot provide that information.",
    ];

    // Add the user's question and name to the responses
    const firstNameQuestion = `${username}: ${searchQuery}`;
    setResponses((prev) => [...prev, firstNameQuestion]);


    // Simulate an API response
    if (responses.length < newResponses.length) {
      setResponses((prev) => [...prev, newResponses[responses.length]]);
    } else {
      setResponses((prev) => [...prev, "CCBot: No more responses available."]);
    }

    // Clear the input after submission
    setSearchQuery("");
  };

  return (
    <div className="chat-container">
      <h2 className="chat-title">Search Student Enrollment Scenarios</h2>
      <p className="chat-welcome">Hello, {username}</p>
      <button
        className="chat-clear-button"
        onClick={clearSearch}
        title="New search"
      >
        <FontAwesomeIcon icon={faAppleWhole} /> New Search
      </button>
      <form onSubmit={handleSearchSubmit} className="chat-form">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Type your question..."
          className="chat-input"
        />
        <button type="submit" className="chat-submit-button">
          <FontAwesomeIcon icon={faLightbulb} />
        </button>
      </form>
      {responses.map((response, index) => (
        <div key={index} className="chat-response">
          {response}
        </div>
      ))}
    </div>
  );
};

export default Chat;
