import { createContext, useState, useEffect, useCallback } from "react";
import axios, { setIsRefreshingToken } from "../api/axios.js"; // Ensure the correct path is used
import ErrorModal from "../components/ErrorModal/ErrorModal";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onLogin = useCallback((loginData) => {
    // Update auth state
    setAuth({
      username: loginData.username,
      display_name: loginData.display_name,
      first_name: loginData.first_name,
      last_name: loginData.last_name,
    });

    setIsLoggedIn(true);
  }, []);

  const onLogout = useCallback(() => {
    // Clear localStorage
    localStorage.removeItem("access_token");
    localStorage.removeItem("display_name");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");

    // Reset auth state
    setAuth({});
    setIsLoggedIn(false);
  }, []);

  const refreshToken = useCallback(async () => {
    console.log("Attempting to refresh token...");
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      ErrorModal("No refresh token available. Please log in again."); 
      setIsLoggedIn(false);
      return;
    }

    try {
      setIsRefreshingToken(true);
      const response = await axios.post("/refresh");
      if (response.status === 200) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        setIsLoggedIn(true);
      }
    } catch (error) {
      ErrorModal("No refresh token available. Please log in again."); 
      setIsLoggedIn(false);
    } finally {
      setIsRefreshingToken(false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshToken, 15 * 60 * 1000); // Continue to call refreshToken every 15 mins
    return () => clearInterval(interval);
  }, [refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isLoggedIn,
        setIsLoggedIn,
        onLogin,
        onLogout,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
