import React from "react";
import { Button, Modal } from "react-bootstrap";

const FilterModal = ({
  show,
  onHide,
  filter,
  setFilter,
  menuType,
  setMenuType,
  resetFilters,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="filterModalTitles">Publication Status</h5>
          <label>
            <input
              type="checkbox"
              checked={filter === "all"}
              onChange={() => setFilter("all")}
            />
            All
          </label>
          <label>
            <input
              type="checkbox"
              checked={filter === "published"}
              onChange={() => setFilter("published")}
            />
            Published
          </label>
          <label>
            <input
              type="checkbox"
              checked={filter === "unpublished"}
              onChange={() => setFilter("unpublished")}
            />
            Unpublished
          </label>
          <h5 className="filterModalTitles">Menu Type</h5>
          <label>
            <input
              type="checkbox"
              checked={menuType === "all"}
              onChange={() => setMenuType("all")}
            />
            All
          </label>
          <label>
            <input
              type="checkbox"
              checked={menuType === "reports"}
              onChange={() => setMenuType("reports")}
            />
            Reports
          </label>
          <label>
            <input
              type="checkbox"
              checked={menuType === "dashboards"}
              onChange={() => setMenuType("dashboards")}
            />
            Dashboards
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          onClick={() => {
            resetFilters();
            onHide();
          }}
        >
          Reset Filter
        </Button>
        <Button variant="primary" onClick={onHide}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
