import React, { useState, useContext, useCallback, useMemo } from "react";
import { NavDropdown } from "react-bootstrap";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import DownloadAll from "../DownloadAll/DownloadAll";
import DownloadAllMerged from "../DownloadAllMerged/DownloadAllMerged";
import DownloadAllDash from "../DownloadAllDash/DownloadAllDash";
import DownloadAllMaster from "../DownloadAllMaster/DownloadAllMaster";
import DownloadAllDashMaster from "../DownloadAllDashMaster/DownloadAllDashMaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const DownloadReportDropdown = () => {
  const { menu, reports, selectedYear, findReportById, selectedDistrict } =
    useContext(SyncReportsContext);
  const [modalState, setModalState] = useState({
    showDownloadAll: false,
    showDownloadAllMerged: false,
    showDownloadAllDash: false,
    showDownloadAllMaster: false,
    showDownloadAllDashMaster: false,
  });
  const [currentMenuItem, setCurrentMenuItem] = useState(null);

  const excludedMenuTitles = useMemo(
    () => [
      "Import Data",
      "Refresh Reports",
      "Sign Off",
      "Toggle Expand",
      "User Management",
      "Welcome",
      "crosscheck",
      "Release Notes",
    ],
    []
  );

  const handleShowModal = useCallback((modalType, menuItem = null) => {
    setModalState({
      showDownloadAll: modalType === "all",
      showDownloadAllMerged: modalType === "merged",
      showDownloadAllDash: modalType === "dash",
      showDownloadAllMaster: modalType === "master",
      showDownloadAllDashMaster: modalType === "dashMaster",
    });
    setCurrentMenuItem(menuItem);
  }, []);

  const handleHideModal = useCallback(() => {
    setModalState({
      showDownloadAll: false,
      showDownloadAllMerged: false,
      showDownloadAllDash: false,
      showDownloadAllMaster: false,
      showDownloadAllDashMaster: false,
    });
    setCurrentMenuItem(null);
  }, []);

  return (
    <>
      <NavDropdown
        title={<FontAwesomeIcon icon={faDownload} title="Download Reports" />}
        id="download-report-dropdown"
        className="nav-dropdown"
      >
        <NavDropdown.Item onClick={() => handleShowModal("master")}>
          <strong>Master Report Download</strong>
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleShowModal("dashMaster")}>
          <strong>Master Dashboard Download</strong>
        </NavDropdown.Item>
        {menu.map(
          (menuItem) =>
            menuItem.reports &&
            !excludedMenuTitles.includes(menuItem.title) &&
            menuItem.menu_type !== "notes" && (
              <NavDropdown.Item
                key={menuItem.menu_id}
                onClick={() => {
                  if (menuItem.menu_id === 8) {
                    handleShowModal("merged", menuItem);
                  } else if (menuItem.menu_type === "dashboard") {
                    handleShowModal("dash", menuItem);
                  } else {
                    handleShowModal("all", menuItem);
                  }
                }}
              >
                {menuItem.title}
              </NavDropdown.Item>
            )
        )}
      </NavDropdown>

      {modalState.showDownloadAll && currentMenuItem && (
        <DownloadAll
          show={modalState.showDownloadAll}
          onHide={handleHideModal}
          menuItem={currentMenuItem}
          reports={reports}
          selectedYear={selectedYear}
          findReportById={findReportById}
          selectedDistrict={selectedDistrict}
        />
      )}

      {modalState.showDownloadAllMerged && currentMenuItem && (
        <DownloadAllMerged
          show={modalState.showDownloadAllMerged}
          onHide={handleHideModal}
          menuItem={currentMenuItem}
          reports={reports}
          selectedYear={selectedYear}
          findReportById={findReportById}
          selectedDistrict={selectedDistrict}
        />
      )}

      {modalState.showDownloadAllDash && currentMenuItem && (
        <DownloadAllDash
          show={modalState.showDownloadAllDash}
          onHide={handleHideModal}
          menuItem={currentMenuItem}
          reports={reports}
          selectedYear={selectedYear}
          findReportById={findReportById}
          selectedDistrict={selectedDistrict}
        />
      )}

      {modalState.showDownloadAllMaster && (
        <DownloadAllMaster
          show={modalState.showDownloadAllMaster}
          onHide={handleHideModal}
        />
      )}

      {modalState.showDownloadAllDashMaster && (
        <DownloadAllDashMaster
          show={modalState.showDownloadAllDashMaster}
          onHide={handleHideModal}
          menuItems={menu.filter(
            (menuItem) => menuItem.reports && menuItem.menu_type === "dashboard"
          )}
        />
      )}
    </>
  );
};

export default React.memo(DownloadReportDropdown);
