import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios.js";
import { Table, Spinner, Alert } from "react-bootstrap"; // Add spinner and alert for loading/error handling
import PrintReleaseNotes from "../PrintReleaseNote/PrintReleaseNote";
import "./ReleaseNotes.css";

function ReleaseNotes() {
  const { reportId } = useParams();
  const [releaseNotes, setReleaseNotes] = useState({
    title: "Release Notes",
    notes: [],
  });
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const { selectedDistrict } = useDistrict();
  const { selectedYear } = useContext(SyncReportsContext);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const response = await axios.post(
          `/cc/run/${selectedDistrict.irn}/report/${
            reportId || "default_id"
          }/year/${selectedYear}`
        );
        const { title, notes } = response.data.report;
        setReleaseNotes({ title, notes });
      } catch (err) {
        setError("Failed to fetch release notes.");
      } finally {
        setLoading(false);
      }
    };
    fetchReleaseNotes();
  }, [reportId, selectedDistrict.irn, selectedYear]);

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" variant="primary" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const releaseNotesTable = (
    <div className="table-wrapper">
      <Table bordered striped hover>
        <thead className="reportData">
          <tr className="table-secondary">
            <th>Type</th>
            <th>Check/Merge Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {releaseNotes.notes.map((note, index) => (
            <tr key={index}>
              <td>{note.menu_title}</td>
              <td>{note.report_title}</td>
              <td>{note.release_note}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <div className="release-notes-container">
      <PrintReleaseNotes
        releaseNotesTable={releaseNotesTable}
        reportTitle={releaseNotes.title}
      />
    </div>
  );
}

export default ReleaseNotes;
