export function isDate(str) {
  // This regex checks for a date with optional time in the format YYYY-MM-DDTHH:MM:SS or with milliseconds YYYY-MM-DDTHH:MM:SS.SSS
  const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?)?$/;
  return regex.test(str);
}

// dateUtils.js
export function formatDate(str) {
  if (!isDate(str)) {
    return str;
  }

  // Split the date from the time, if time is present
  const datePart = str.split("T")[0];
  const parts = datePart.split("-");
  const year = parts[0].slice(-2); // Get the last two digits of the year
  const month = parts[1];
  const day = parts[2];

  // Return the formatted date as MM/DD/YY
  return `${month}/${day}/${year}`;
}

