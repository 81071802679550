import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import { Button } from "react-bootstrap";
import "./MergedFiles.css";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import ErrorModal from "../ErrorModal/ErrorModal";

// Utility function to sanitize filenames
const sanitizeFilename = (filename) => {
  return filename
    .replace(/[^a-z0-9 ]/gi, "") // Remove non-alphanumeric characters except spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

// Utility function to format date strings
// const formatDateString = (dateString) => {
//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) {
//     return dateString;
//   }
//   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
//     2,
//     "0"
//   )}-${String(date.getDate()).padStart(2, "0")}`;
// };

function MergedFiles() {
  const { reportId } = useParams();
  const { reports, selectedYear } = useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();

  const [title, setTitle] = useState("Report Title");
  const [moreInfo, setMoreInfo] = useState("");
  const [buildExport, setBuildExport] = useState(false);
  const [error, setError] = useState("");

  // Reset state when reportId changes
  useEffect(() => {
    const resetState = () => {
      setTitle("Report Title");
      setMoreInfo("");
      setBuildExport(false);
      setError("");
    };

    resetState();

    const reportDetails = reports[reportId]?.report;
    if (reportDetails) {
      setTitle(reportDetails.title || "Report Title");
      setMoreInfo(reportDetails.more_info || "");
    }
  }, [reportId, reports]);

  const runExport = useCallback(async () => {
    setBuildExport(true);
    try {
      const response = await axios.post(
        `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
      );
      const cr = "\r\n";
      const dataString =
        response.data.headers +
        cr +
        response.data.data.map((row) => row.row + cr).join("");
      downloadFile(dataString);
    } catch (error) {
      setError(
        `There was an error while creating the export: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setBuildExport(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict, reportId, selectedYear, title]);

  const downloadFile = (data) => {
    const sanitizedTitle = sanitizeFilename(title);
    const timestamp = new Date().toISOString().replace(/:/g, "-").split("T")[0];
    const filename = `${sanitizedTitle}_${timestamp}.csv`;
    const blob = new Blob([data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleCloseError = () => setError("");

  return (
    <div className="merged-files__table-responsive">
      <div className="merged-files__card">
        <div className="merged-files__card-header">{title}</div>
        <div className="merged-files__card-body">
          <p
            className="merged-files__card-text"
            dangerouslySetInnerHTML={{ __html: moreInfo }}
          ></p>
          {!buildExport ? (
            <Button
              onClick={runExport}
              variant="primary"
              size="sm"
              className="merged-files__export-btn"
            >
              Export
            </Button>
          ) : (
            <Button
              variant="secondary"
              size="sm"
              disabled
              className="merged-files__export-btn--disabled"
            >
              Building Export File
            </Button>
          )}
        </div>
      </div>
      {error && (
        <ErrorModal show={!!error} error={error} onClose={handleCloseError} />
      )}
    </div>
  );
}

export default MergedFiles;
