import React, { useState, useContext, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import ErrorModal from "../ErrorModal/ErrorModal";
import Loading from "react-loading";

// Utility function to format dates consistently
const formatDateString = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date.getTime())
    ? dateString
    : `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
};

// Utility function to generate CSV content from data
const generateCSVContent = (data) => {
  const csvContent = [
    data.headers,
    ...data.data.map((row) =>
      row.row
        .split(",")
        .map((cell) =>
          /\d{4}-\d{2}-\d{2}/.test(cell) ? formatDateString(cell) : cell
        )
        .join(",")
    ),
  ].join("\r\n");
  return csvContent;
};

// Utility function to sanitize file names
const sanitizeFilename = (filename) => {
  return filename
    .replace(/[^a-z0-9 ]/gi, "")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const DownloadAllMaster = ({ show, onHide }) => {
  const { selectedDistrict } = useDistrict();
  const { menu, selectedYear } = useContext(SyncReportsContext);
  const [isDownloading, setIsDownloading] = useState(false);
  // eslint-disable-next-line
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  // eslint-disable-next-line
  const [totalReports, setTotalReports] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");

  const excludedMenuTitles = [
    "Import Data",
    "Refresh Reports",
    "Sign Off",
    "Toggle Expand",
    "User Management",
    "Welcome",
    "crosscheck",
    "Release Notes",
  ];

  const resetExportState = () => {
    setIsDownloading(false);
    setCurrentReportIndex(0);
    setTotalReports(0);
    setShowErrorModal(false);
    setErrorDetails("");
  };

  const toggleModal = useCallback(() => {
    if (!show) {
      resetExportState();
    }
    onHide();
  }, [show, onHide]);

  const fetchReportData = async (report, parentFolder, validReports, zip) => {
    try {
      const response = await axios.post(
        `/cc/export/${selectedDistrict.irn}/report/${report.report_id}/year/${selectedYear}`
      );

      if (response.data && response.data.data.length > 0) {
        const csvContent = generateCSVContent(response.data);
        const folder =
          parentFolder || zip.folder(sanitizeFilename(report.menu_title));
        folder.file(`${sanitizeFilename(report.menu_title)}.csv`, csvContent);
        validReports.push(report);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error(
          `Error for report ${report.report_id}: ${error.response.data.error}`
        );
      }
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const zip = new JSZip();
      const validReports = [];

      for (const menuItem of menu) {
        if (
          menuItem.reports &&
          !excludedMenuTitles.includes(menuItem.title) &&
          menuItem.menu_type !== "dashboard"
        ) {
          const parentFolder = zip.folder(sanitizeFilename(menuItem.title));
          for (const report of menuItem.reports) {
            await fetchReportData(report, parentFolder, validReports, zip);
          }
        }
      }
      //

      const total = validReports.length;
      setTotalReports(total);

      if (total > 0) {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(
          zipBlob,
          `All_Reports_${
            selectedDistrict.name
          }_${selectedYear}_${new Date().toLocaleDateString()}.zip`
        );
      } else {
        console.warn("No reports to download.");
      }

      toggleModal(); // Close modal when all API calls are complete
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setIsDownloading(false);
      setErrorDetails(error.message || "An unknown error occurred");
      setShowErrorModal(true);
    }
  };

  const handleErrorModalClose = () => setShowErrorModal(false);

  return (
    <>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Download All Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to download all reports?</p>
          <p>
            This action will download all reports as individual CSV files within
            a single ZIP file. Please note that this process may take a few
            minutes depending on the file sizes.
          </p>
          <p>
            <strong>
              Please note: reports without current data will not be downloaded
            </strong>
          </p>
          {isDownloading && (
            <div className="loading-container">
              <Loading type="bubbles" color="#007bff" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Loading type="bubbles" color="#fff" width={20} height={20} />
            ) : (
              "Download"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal
        show={showErrorModal}
        onHide={handleErrorModalClose}
        errorCode={500}
        errorMessage={`Failed to download the following reports: ${errorDetails}`}
      />
    </>
  );
};

export default DownloadAllMaster;
