import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { formatDate, isDate } from "../../hooks/dateUtils";

const PrintReport = ({ reportId }) => {
  const { reports } = useContext(SyncReportsContext);

  const handlePrintReport = async () => {
    try {
      const selectedReportData = reports[reportId];
      if (!selectedReportData) {
        console.error("Report data not found");
        return;
      }

      const printWindow = window.open("", "_blank");
      const style = `
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 20px;
        }
        h1, h5 {
          margin-bottom: 10px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f4f4f4;
          white-space: normal;
        }
        thead th {
          background-color: #e0e0e0;
          font-weight: bold;
        }
        .small-col { width: 50px; }
        .medium-col { width: 100px; }

        @media print {
          @page { size: landscape; }
          body {
            margin: 0;
          }
          th, td {
            font-size: 10px;
          }
        }
      </style>
      `;

      const headers = selectedReportData.report.columns
        .filter((column) => column.title !== "Building Name")
        .concat([{ title: "Review Status" }, { title: "Review Notes" }])
        .map((column) => `<th>${column.title}</th>`)
        .join("");

      const rows = selectedReportData.data
        .map((item) => {
          return `<tr>${selectedReportData.report.columns
            .filter((column) => column.title !== "Building Name")
            .concat([
              { data_col_name: "reviewed.reviewed" },
              { data_col_name: "reviewed.note" },
            ])
            .map((column) => {
              let cellData = item.fields[column.data_col_name];
              if (column.data_col_name === "reviewed.reviewed") {
                cellData = item.reviewed.reviewed ? "Reviewed" : "Not Reviewed";
              } else if (column.data_col_name === "reviewed.note") {
                cellData = item.reviewed.note || "";
              }
              let displayData =
                cellData === null
                  ? ""
                  : isDate(cellData)
                  ? formatDate(cellData)
                  : cellData;
              return `<td>${displayData}</td>`;
            })
            .join("")}</tr>`;
        })
        .join("");

      const content = `
        <html>
          <head>
            <title>${selectedReportData.report.title}</title>
            ${style}
          </head>
          <body>
            <h1>${selectedReportData.report.title}</h1>
            <h5>Report generated on: ${new Date().toLocaleString()}</h5>
            <h5>More information: ${
              selectedReportData.report.more_info ||
              "No information available for this report."
            }</h5>
            <table>
              <thead>
                <tr>${headers}</tr>
              </thead>
              <tbody>${rows}</tbody>
            </table>
          </body>
        </html>
      `;

      printWindow.document.write(content);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 200);
    } catch (error) {
      console.error("Failed to print report: ", error);
    }
  };

  return (
    <Button variant="dark" onClick={handlePrintReport} title="Print Report">
      <FontAwesomeIcon icon={faPrint} />
    </Button>
  );
};

export default PrintReport;
