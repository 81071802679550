import React, { useContext } from "react";
import { BlurContext } from "../../context/BlurContext";
import "./Blur.css";

const Blur = ({ children, columnKey }) => {
  const { isBlurred } = useContext(BlurContext);
  // TODO: In the future this should be an array determine through the admin portal
  const colsToBlur = [
    "employeeid",
    "emp_name_1",
    "emp_name_2",
    "employeeid_050",
    "employeename",
    "employeename_060",
    "firstname",
    "firstname_290",
    "lastname",
    "lastname_310",
    "ssid",
    "stateid_050",
    "stateid_1",
    "stateid_2",
    "statestaffid",
    "statestaffid_270",
    "statestdntid",
    "statestdntid_050",
    "statestudentid",
    "statestudentid_050",
    "statestudentid_110",
    "statestudentid_110",
    "studentfirstname_330",
    "studentlastname_350",
    "first_name",
    "last_name",
    "username",
  ];

  const shouldBlur =
    isBlurred && columnKey && colsToBlur.includes(columnKey.toLowerCase());

  return <div className={shouldBlur ? "blur" : ""}>{children}</div>;
};

export default Blur;
