import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout({ onLogout }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear any existing token refresh intervals
    if (window.tokenRefreshInterval) {
      clearInterval(window.tokenRefreshInterval);
    }

    // Remove authentication-related items from localStorage
    ["access_token", "crosscheck_role", "display_name", "first_name", "last_name", "refresh_token", "username"].forEach(item => localStorage.removeItem(item));
    localStorage.setItem("login_time", new Date().getTime());

    // Update authenticated state in App.js
    onLogout();

    // Navigate to login page
    navigate("/login");
  }, [navigate, onLogout]);

  return null;
}

export default Logout;