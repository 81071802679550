import React from "react";
import { Table, Badge, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuItems = ({
  sortedMenu,
  handleViewReports,
  handleSelectMenu,
  handleDeleteMenu,
  confirmDelete,
  handleCancelDelete,
  displayIcon,
  setEditMenu,
}) => {
  return (
    <div className="admin-table-container">
      <h2>Main Menu Items</h2>
      <Table striped bordered hover className="admin-table">
        <thead className="adminMenuHeaders">
          <tr>
            <th>Icon</th>
            <th>Menu Title</th>
            <th>Menu Type</th>
            <th>Sort</th>
            <th>Reports</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedMenu.map((menuItem) => (
            <tr key={menuItem.menu_id} className="pointer">
              <td>{displayIcon(menuItem.icon_active)}</td>
              <td onClick={() => handleViewReports(menuItem)}>
                {menuItem.title}
              </td>

              <td>{menuItem.menu_type}</td>
              <td>
                <Badge bg="secondary" title="Sort By #">
                  {menuItem.sort_by}
                </Badge>
              </td>
              <td>
                <Badge bg="info" title="Total Reports">
                  {menuItem.reports.length}
                </Badge>
              </td>
              <td className="nowrap">
                <Button
                  className="adminEditButton"
                  variant="info"
                  size="sm"
                  title="Edit Menu Item"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectMenu(menuItem);
                    setEditMenu(true); 
                  }}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>

                <Button
                  className="adminEditButton"
                  variant="danger"
                  size="sm"
                  title="Delete Menu Item"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteMenu(menuItem);
                  }}
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
                {confirmDelete && (
                  <>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelDelete();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMenu(menuItem);
                      }}
                    >
                      Confirm Delete
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MenuItems;
