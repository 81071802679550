import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../components/Logo/Logo";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Links from "../components/Links/Links";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import "../App.css";

function Layout() {
  return (
    <div className="App">
      <div className="header-container">
        <Logo />
        <Breadcrumbs />
        <Links />
      </div>
      <div className="row">
        <SideNavigation />
        <Outlet />{" "}
        {/* This is where the nested route components will be rendered
        App.js stores the routing information - we pass it here through the outlet */}
      </div>
    </div>
  );
}

export default Layout;
