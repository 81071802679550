import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import axios from "../../api/axios";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ErrorModal from "../ErrorModal/ErrorModal";

const formatDateString = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString;
  }
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

const sanitizeFilename = (filename) => {
  return filename
    .replace(/[^a-z0-9 ]/gi, "") // Remove non-alphanumeric characters except spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

const generateCSVContent = (data) => {
  const cr = "\r\n";
  let csvContent = data.headers + cr;
  data.data.forEach((row) => {
    const formattedRow = row.row
      .split(",")
      .map((cell) => {
        return cell.match(/\d{4}-\d{2}-\d{2}/) ? formatDateString(cell) : cell;
      })
      .join(",");
    csvContent += formattedRow + cr;
  });
  return csvContent;
};

const DownloadAll = ({ show, onHide, menuItem, reports, selectedYear }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  const [totalReports, setTotalReports] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  const { selectedDistrict } = useDistrict();

  const resetExportState = () => {
    setIsDownloading(false);
    setCurrentReportIndex(0);
    setTotalReports(0);
    setShowErrorModal(false);
    setErrorDetails("");
  };

  const toggleModal = () => {
    if (!show) {
      resetExportState();
    }
    onHide();
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const zip = new JSZip();
      const reportsToDownload = menuItem.reports.filter((report) => {
        const updatedReport = reports[report.report_id];
        return updatedReport && updatedReport.data;
      });

      const validReports = [];

      // Fetch and filter valid reports before downloading
      for (const report of reportsToDownload) {
        const reportId = report.report_id;
        try {
          const response = await axios.post(
            `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
          );

          if (response.data.data.length > 0) {
            validReports.push(report);
          }
        } catch (error) {
          console.error(`Error fetching report ${reportId}: ${error.message}`);
        }
      }

      // Set the total number of valid reports for progress tracking
      const total = validReports.length;
      setTotalReports(total);

      // Download each valid report
      for (const [index, report] of validReports.entries()) {
        setCurrentReportIndex(index + 1);

        const response = await axios.post(
          `/cc/export/${selectedDistrict.irn}/report/${report.report_id}/year/${selectedYear}`
        );

        const csvContent = generateCSVContent(response.data);
        const sanitizedTitle = sanitizeFilename(report.menu_title);
        const filename = `${sanitizedTitle}.csv`;
        zip.file(filename, csvContent);
      }

      if (Object.keys(zip.files).length === 0) {
        console.warn("No reports to download.");
        setIsDownloading(false);
        onHide();
        return;
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(
        zipBlob,
        `${menuItem.title}_${
          selectedDistrict.name
        } for ${selectedYear} on ${new Date().toLocaleDateString()}.zip`
      );

      toggleModal(); // Close modal when all API calls are complete
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setIsDownloading(false);
      setErrorDetails(error.message || "An unknown error occurred");
      setShowErrorModal(true);
    }
  };

  const handleErrorModalClose = () => setShowErrorModal(false);

  return (
    <>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Download Reports for {menuItem.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to download all reports for {menuItem.title}?
          </p>
          <p>
            This action will download all reports as individual CSV files within
            a single ZIP file. Please note that this process may take a few
            minutes depending on the file sizes and number of reports.
          </p>
          <p>
            <strong>
              Please note: reports without current data will not be downloaded
            </strong>
          </p>
          {isDownloading && (
            <div className="loading-container">
              <Spinner animation="border" size="sm" style={{ color: "blue" }} />
              <span>{` Downloading ${currentReportIndex} of ${totalReports}`}</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Download"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal
        show={showErrorModal}
        onHide={handleErrorModalClose}
        errorCode={500}
        errorMessage={`Failed to download the following reports: ${errorDetails}`}
      />
    </>
  );
};

export default DownloadAll;
