import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../../components/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Card, ProgressBar, Table, Modal, Button } from "react-bootstrap";
import "./Load.css";

function Load() {
  const {
    syncStatus,
    records,
    reportData,
    crosscheckAdmin,
    dataSources,
    // eslint-disable-next-line
    loading,
    isModalClosedManually,
    setIsModalClosedManually,
  } = useContext(SyncReportsContext);

  const { selectedDistrict } = useDistrict();
  const navigate = useNavigate();

  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [, setRemainingReports] = useState(0);
  // eslint-disable-next-line
  const [showModal, setShowModal] = useState(false);
  const [, setTotalReports] = useState(0);
  const [isAdminTableVisible, setIsAdminTableVisible] = useState(false);

  // Only show the modal when loading is complete and syncStatus is success
  const shouldShowModal = syncStatus === "success" && !isModalClosedManually;

  useEffect(() => {
    if (selectedDistrict) {
      let loadingCount = 0;
      const reportCount = Object.values(reportData).length;
      Object.values(reportData).forEach((report) => {
        if (report.loading) {
          loadingCount++;
        }
      });

      setRemainingReports(loadingCount);
      setTotalReports(reportCount);

      // Reset modal close flag when district changes or new sync starts
      if (syncStatus === "syncing") {
        setIsModalClosedManually(false);
      }

      if (!isModalClosedManually && syncStatus === "success") {
        setShowModal(loadingCount > 0);
      }

      const completed = reportCount - loadingCount;
      const percentage = (completed / reportCount) * 100;
      setPercentageCompleted(Math.round(percentage));

      // When modal reaches 100%, close it
      if (percentage === 100) {
        setIsModalClosedManually(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, selectedDistrict, syncStatus]);

  // reset the modal state each time the district change or we leave the page
  useEffect(() => {
    return () => {
      setIsModalClosedManually(false);
    };
  }, [setIsModalClosedManually]);

  const renderSyncStatus = () => {
    const messageMap = {
      syncing: (
        <div className="info-message">
          <h3 className="syncTitle">Synchronization in Progress</h3>
          <p className="disclaimer">
            The Data Collector is currently syncing. The Reports menu will
            become available once synchronization is complete.
          </p>
          <ProgressBar animated now={100} />
        </div>
      ),
      success: (
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} className="icon-success" />
          <p className="syncSuccessMessage">
            Crosscheck is up to date with the Data Collector.
          </p>
        </div>
      ),
      error: (
        <div className="error-message">
          <FontAwesomeIcon icon={faTimesCircle} className="icon-error" />
          <p className="syncErrorMessage">
            Synchronization error - please retry or contact support.
          </p>
        </div>
      ),
    };
    return messageMap[syncStatus] || null;
  };

  const renderTableRows = () =>
    records.flatMap((collection, collectionIndex) =>
      collection.tables.map((table, tableIndex) => (
        <tr
          key={`${collectionIndex}-${tableIndex}`}
          className={table.success ? "alert-success" : "alert-danger"}
        >
          <td>{collection.collection_name}</td>
          <td>{collection.fiscal_year}</td>
          <td>{table.table}</td>
          <td>{table.message}</td>
        </tr>
      ))
    );

  const renderAdminTableRows = () => {
    return Object.entries(dataSources).length > 0 ? (
      Object.entries(dataSources).map(([key, src]) => (
        <tr key={key}>
          <td>{key}</td>
          <td>{src.collection_type}</td>
          <td>{src.description}</td>
          <td>{src.table_name}</td>
          <td>
            {src.collection_date
              ? new Date(src.collection_date).toLocaleDateString()
              : "N/A"}
          </td>
          <td>
            {src.last_loaded
              ? new Date(src.last_loaded).toLocaleDateString()
              : "N/A"}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          No data sources available.
        </td>
      </tr>
    );
  };

  const toggleAdminTableVisibility = () => {
    setIsAdminTableVisible(!isAdminTableVisible);
  };

  const handleCloseLoadingModal = () => {
    setIsModalClosedManually(true);
  };

  return (
    <Card className="load-container text-center">
      {!selectedDistrict && (
        <div className="welcome-screen">
          <img
            src={require("./header_logo.png")}
            alt="Header Logo"
            className="welcome-screen-logo"
          />
          <div className="welcome-screen-title">
            <h3>Welcome to CrossCheck</h3>
            <p>Please select a district to begin</p>
          </div>
        </div>
      )}
      {selectedDistrict && renderSyncStatus()}
      {selectedDistrict && records.length > 0 && (
        <Table striped bordered hover size="lg" className="bg-white">
          <thead>
            <tr>
              <th>Collection</th>
              <th>Fiscal Year</th>
              <th>Table</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>
      )}
      {selectedDistrict && crosscheckAdmin && (
        <div className="admin-table-container">
          <h4
            onClick={toggleAdminTableVisibility}
            style={{ cursor: "pointer" }}
          >
            Admin Table{" "}
            <FontAwesomeIcon
              icon={isAdminTableVisible ? faChevronUp : faChevronDown}
            />
          </h4>
          {isAdminTableVisible && (
            <Table striped bordered hover size="sm">
              <thead
                className="thead-dark"
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1000",
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th>Record Type</th>
                  <th>Collection Type</th>
                  <th>Description</th>
                  <th>Table Name</th>
                  <th>Date Collected</th>
                  <th>Imported into CC</th>
                </tr>
              </thead>
              <tbody>{renderAdminTableRows()}</tbody>
            </Table>
          )}
        </div>
      )}
      {selectedDistrict && (
        <Modal
          show={shouldShowModal}
          onHide={handleCloseLoadingModal}
          centered
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Synchronization in Progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your reports are updating. Please wait while we finalize the
              updates. The navigation menu will automatically refresh with the
              latest data once synchronization is complete.
            </p>
            <p>
              <strong>
                If you close this modal before the sync finishes, the process
                will continue in the background.
              </strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            {percentageCompleted >= 1 && (
              <div className="w-100">
                <ProgressBar
                  animated
                  now={percentageCompleted}
                  label={`${percentageCompleted}%`}
                />
              </div>
            )}
            <Button variant="primary" onClick={() => navigate("/logout")}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Card>
  );
}

export default Load;
