import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Context imports
import { SyncReportsContext } from "../../context/SyncReportsProvider";

// Component imports
import Blur from "../Blur/Blur";
import MoreInfo from "../MoreInfo/MoreInfo";
import DashboardExtract from "../DashboardExtract/DashboardExtract";
import handlePrintAllReports from "../PrintAllDashboards/PrintAllDash";
import handlePrintSingleReport from "../PrintDashboard/PrintDash";

// Data table import
import DataTable from "react-data-table-component";

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPrint } from "@fortawesome/free-solid-svg-icons";

// Bootstrap imports
import { Row, Col } from "react-bootstrap";

// Style import
import "./Dashboard.css";

const Dashboard = () => {
  const { reports, menuItem, dataSources, selectedYear } =
    useContext(SyncReportsContext);
  const location = useLocation();
  const [gradDataDate, setGradDataDate] = useState([]); // eslint-disable-line no-unused-vars
  const [gcDataDate, setGcDataDate] = useState([]); // eslint-disable-line no-unused-vars
  const [reportData, setReportData] = useState({});
  const [studentDataDate, setStudentDataDate] = useState("");
  const [staffDataDate, setStaffDataDate] = useState("");
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [selectedReportInfo, setSelectedReportInfo] = useState(null);

  const dashboardNotes = location.state?.dashboardNotes;

  useEffect(() => {
    const formatDate = (dataSourceKey, defaultMsg) => {
      const date = dataSources[dataSourceKey]?.collection_date;
      return date
        ? new Date(date).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : defaultMsg;
    };

    setStudentDataDate(
      formatDate("FS", selectedYear + " data has not been loaded")
    );
    // console.log("studentDataDate", studentDataDate);
    setStaffDataDate(
      formatDate("CI", selectedYear + " data has not been loaded")
    );
    setGcDataDate(
      formatDate("GC_GRAD", selectedYear + " data has not been loaded")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources]);

  useEffect(() => {
    setReportData({});
    const reportIds =
      location.state?.reportIds ||
      new URLSearchParams(location.search).get("report_ids")?.split(",");

    if (reportIds && Array.isArray(reportIds)) {
      reportIds.forEach((id) => {
        if (
          reports[id] &&
          reports[id].report &&
          reports[id].report.child_report
        ) {
          setReportData((prevData) => ({
            ...prevData,
            [id]: reports[id],
          }));
        }
      });
    }
  }, [location, reports]);

  const handleMoreInfoClick = (reportId) => {
    const parentReport = reportData[reportId];
    const childReportId = parentReport?.report?.child_report?.report_id;
    // eslint-disable-next-line no-unused-vars
    const childReport = reports[childReportId];

    let infoContent = "";
    if (parentReport && parentReport.report.more_info) {
      infoContent += `${parentReport.report.more_info}`;
    }
    if (infoContent) {
      setSelectedReportInfo(infoContent);
      setShowMoreInfo(true);
    }
  };

  const dashboardTitle =
    location.state?.dashboardTitle || menuItem?.title || "Dashboard Title";

  const createColumns = (reportColumns) => {
    return reportColumns.map((column) => ({
      name: (
        <div className="column-header">
          {column.badge && (
            <span className="badge badge-dark">{column.badge}</span>
          )}
          <div>{column.title}</div>
        </div>
      ),
      selector: (row) => row[column.data_col_name],
      sortable: true,
      wrap: true,
      cell: (row) => {
        // Extract the value from the row for this column
        const value = row[column.data_col_name];

        // Format the value based on column settings, including decimal places from admin setting
        const formattedValue = formatValue(
          value,
          column.title,
          column.data_type // Pass data_type from column definition
        );

        return (
          <Blur columnKey={column.data_col_name}>{formattedValue}</Blur> // Wrap cell content with Blur
        );
      },
    }));
  };

  const formatValue = (value, columnTitle, dataType) => {
    // Convert the value to a float if possible
    const numericValue = parseFloat(value);

    // Extract the number of decimal places from dataType if formatted as "number:x"
    let decimalPlaces = 0; // Default if no dataType is provided or doesn't match the pattern
    if (dataType && dataType.startsWith("number:")) {
      decimalPlaces = parseInt(dataType.split(":")[1], 10);
    }

    // Check if the value is numeric and should be rounded
    if (!isNaN(numericValue)) {
      return numericValue.toFixed(decimalPlaces);
    }

    // If value is not numeric, return it as is
    return value;
  };

  const renderReportCard = (id, report) => {
    // Check if the report or its nested properties are undefined or empty
    if (!report || !report.report || !report.data || report.data.length === 0) {
      return (
        <Col key={id} lg={6} md={12} sm={12}>
          <div className="report-card">
            <h2 className="report-title bg-light text-warning">
              {report ? report.report.title : "No Information Available"}
            </h2>
            <div>No information available</div>
          </div>
        </Col>
      );
    }

    // Proceed if the report and its data are defined
    const titleClasses = `report-title ${
      report.report.publish ? report.report.menu_bgcolor : "bg-light"
    } ${report.report.publish ? report.report.menu_color : "text-warning"}`;

    const data = report.data.map((item) => item.fields || item);
    const columns = createColumns(report.report.columns);

    // Safely access child report if it exists
    const hasChildReport = report.report.child_report?.report_id;
    const childReportData = hasChildReport ? reports[hasChildReport] : null;

    return (
      <Col key={id} lg={6} md={12} sm={12}>
        <div className="report-card" id={`report-${id}`}>
          <h2 className={titleClasses}>{report.report.title}</h2>
          <div className="report-actions">
            {childReportData && (
              <DashboardExtract
                reportId={childReportData.report?.report_id}
                reportTitle={childReportData.report?.title}
                reportData={childReportData.data}
                reportColumns={childReportData.report?.columns}
              />
            )}
            {report.report.more_info && (
              <button
                className="more-info-btn non-printable"
                onClick={() => handleMoreInfoClick(id)}
              >
                <FontAwesomeIcon icon={faInfo} title="More Info" />
              </button>
            )}
            <button
              className="print-btn hide-on-print"
              onClick={() => handlePrintSingleReport(id, reportData)}
            >
              <FontAwesomeIcon icon={faPrint} title="Print" />
            </button>
          </div>
          {data.length > 0 ? (
            <DataTable
              className="dashboard-table"
              columns={columns}
              data={data}
              customStyles={customStyles}
              highlightOnHover
              fixedHeader
            />
          ) : (
            <div>No information available</div>
          )}
        </div>
      </Col>
    );
  };

  return (
    <div className="dashboard-container">
      <div className="print-container" style={{ display: "none" }}></div>
      <div className="dashboard-header">
        <h1 className="dashboard-title">{dashboardTitle}</h1>
        <div className="dashboard-dates">
          <div>
            <strong>Student data as of: {studentDataDate}</strong>
          </div>
          {dashboardTitle.includes("Graduation") ||
          dashboardTitle.includes("Dropout") ? null : (
            <div>
              <strong>Staff Data as of: {staffDataDate}</strong>
            </div>
          )}

          {dashboardTitle.includes("Graduation") ||
          dashboardTitle.includes("Dropout") ? (
            <>
              <div>
                <strong>Graduation Data as of: {gcDataDate}</strong>
              </div>
            </>
          ) : null}
        </div>
        <div className="dashboard-actions">
          <button
            className="action-btn print-all"
            onClick={() =>
              handlePrintAllReports(
                reportData,
                dashboardTitle,
                studentDataDate,
                staffDataDate,
                gcDataDate
              )
            }
          >
            <FontAwesomeIcon icon={faPrint} title="Print All Dashboards" />
          </button>
          {dashboardNotes && dashboardNotes.trim() !== "" && (
            <button
              className="action-btn menuItem-more-info"
              onClick={() => {
                setSelectedReportInfo(dashboardNotes);
                setShowMoreInfo(true);
              }}
            >
              <FontAwesomeIcon icon={faInfo} title="More Info For Dashboard" />
            </button>
          )}
        </div>
      </div>
      <Row>
        {Object.keys(reportData).map((id) =>
          renderReportCard(id, reportData[id])
        )}
      </Row>
      {showMoreInfo && (
        <MoreInfo
          show={showMoreInfo}
          handleClose={() => setShowMoreInfo(false)}
          moreInfoContent={selectedReportInfo}
        />
      )}
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "700",
      color: "#4a4a4a",
      backgroundColor: "#eaeaea",
    },
  },
  rows: {
    style: {
      fontSize: "14px",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
};

export default Dashboard;
