import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./help.css";
import functions1 from "./HelpImgs/functions1.png";
import functions2 from "./HelpImgs/functions2.2.png";
import functions3 from "./HelpImgs/functions3.2.png";
import functions4 from "./HelpImgs/functions4.2.png";
import functions5 from "./HelpImgs/functions5.png";
import functions6 from "./HelpImgs/functions6.png";
import functions7 from "./HelpImgs/functions7.png";
import functions8 from "./HelpImgs/function8.png";
import functions9 from "./HelpImgs/function9.png";
import functions10 from "./HelpImgs/function10.png";

const HelpFunctions = () => {
  return (
    <div className="help-container">
      <Row>
        <Col md={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Functions</h1>
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    The top navigation bar can be used to select the ITC, Data
                    Collector, and District. CrossCheck will automatically
                    select based on your access. Locally added users only have
                    access to one district, so there will be no dropdowns
                    present, each area will be automatically populated. ITC
                    users with access to more than one data collector will have
                    to choose a data collector and a district after logging in
                    each time.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions1}
                    alt="org, data collector and district dropdowns"
                    // style={{ width: "100%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    On the right side, under the user’s name, there are some
                    tool icons. Click the home icon to navigate to the home
                    page. The incognito person icon is to apply the blur feature
                    to personally identifiable information.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions2}
                    alt="home and blur icons"
                    style={{ width: "80%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Row className="doubleCard">
            <Col md={5}>
              <Card className="help-card mb-4">
                <Card.Body className="help-card-body">
                  <h3 className="help-heading">Active Directory Users</h3>
                  <Card.Img
                    className="help-img-fluid"
                    src={functions3}
                    alt="active directory user quick links"
                    style={{ width: "70%", height: "auto", margin: "0 auto" }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={5}>
              <Card className="help-card mb-4">
                <Card.Body className="help-card-body">
                  <h3 className="help-heading">Locally Added Users</h3>
                  <Card.Img
                    className="help-img-fluid"
                    src={functions4}
                    alt="local user quick links"
                    style={{ width: "80%", height: "auto", margin: "0 auto" }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    The blur data feature causes personally identifiable data to
                    be blurred. <span style={{ color: "red" }}>Caution:</span>{" "}
                    Any export will have the actual data with no blur.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions5}
                    alt="blurred student data"
                    style={{ width: "80%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    CrossCheck is separated into sections: Dashboards, Checks
                    (by area), and merged files/reports. Use the navigation menu
                    to choose an area in CrossCheck. The navigation menu will
                    slide out when you choose an area to show reports in each
                    area. Reports within an area are sorted in alphabetical
                    order. The Search Reports box at the top of the navigation
                    menu allows the user to search for a check or merged file
                    based on name.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions6}
                    alt="navigation links"
                    style={{ width: "60%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Use the circled toggle arrow on the navigation menu to
                    collapse it entirely and re-open as desired.
                  </p>
                  <p className="help-paragraph">
                    Click Refresh Reports in the navigation menu to start a
                    fresh sync. Once clicked, the user is taken to the Welcome /
                    Sync page where they can see when the sync finishes. This
                    can be used when a user has a fresh collection to sync while
                    still using CrossCheck.
                  </p>
                  <p className="help-paragraph">
                    Sign off provides a second place to log out of CrossCheck.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions7}
                    alt="collapsed navigation menu"
                    style={{ width: "65%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* New Code Additions 9/3/24 */}
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Users can export all data in CrossCheck as a Zip file using
                    the mass download feature.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions8}
                    alt="collapsed navigation menu"
                    style={{ width: "65%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={6}>
                  <p className="help-paragraph">
                    Users can download by each report area, or download all
                    dashboards or all checks.
                  </p>
                  <p className="help-paragraph" style={{ fontWeight: "bold" }}>
                    Once a selection is made, a download window will appear.
                  </p>
                </Col>
                <Col md={6} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions9}
                    alt="collapsed navigation menu"
                    style={{ width: "65%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Choose download to begin the file download. Once complete,
                    your browser will complete the download process of the zip
                    file. This process may take a while depending on how many
                    reports you are downloading and how much data is included.
                  </p>
                </Col>
                <Col md={7} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={functions10}
                    alt="collapsed navigation menu"
                    style={{ width: "75%", height: "auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpFunctions;
