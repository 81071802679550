import React, { useContext, useState, useCallback } from "react";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ErrorModal from "../ErrorModal/ErrorModal";
import Loading from "react-loading";

// Utility function to format date strings
const formatDateString = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date.getTime())
    ? dateString
    : `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
};

// Utility function to sanitize filenames
const sanitizeFilename = (filename) => {
  return filename
    .replace(/[^a-z0-9 ]/gi, "") // Remove non-alphanumeric characters except spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    .replace(/District(?=\w)/gi, "District "); // Add a space after "District" if followed by another word
};

// Utility function to generate CSV content
const generateCSVContent = (data) => {
  const cr = "\r\n";
  let csvContent = data.headers + cr;
  data.data.forEach((row) => {
    const formattedRow = row.row
      .split(",")
      .map((cell) =>
        cell.match(/\d{4}-\d{2}-\d{2}/) ? formatDateString(cell) : cell
      )
      .join(",");
    csvContent += formattedRow + cr;
  });
  return csvContent;
};

const DownloadAllDashMaster = ({ show, onHide, menuItems }) => {
  const { selectedYear } = useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();

  const [downloadState, setDownloadState] = useState({
    isDownloading: false,
    showErrorModal: false,
    errorDetails: "",
  });

  const resetDownloadState = useCallback(() => {
    setDownloadState({
      isDownloading: false,
      showErrorModal: false,
      errorDetails: "",
    });
  }, []);

  const toggleModal = useCallback(() => {
    if (!show) resetDownloadState();
    onHide();
  }, [show, onHide, resetDownloadState]);

  const downloadAllReportsAsZip = useCallback(async () => {
    setDownloadState((prevState) => ({ ...prevState, isDownloading: true }));

    try {
      const zip = new JSZip();

      for (const menuItem of menuItems) {
        const childReports = menuItem.reports.filter(
          (report) => report.child_report?.report_id
        );

        const menuItemFolder = zip.folder(sanitizeFilename(menuItem.title));

        for (const report of childReports) {
          const reportId = report.child_report.report_id;

          try {
            const response = await axios.post(
              `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
            );

            if (response.data.data.length > 0) {
              const csvContent = generateCSVContent(response.data);
              menuItemFolder.file(
                `${sanitizeFilename(report.child_report.title)}.csv`,
                csvContent
              );
            }
          } catch (error) {
            if (error.response && error.response.status === 400) {
              console.warn(
                `Skipping report ${
                  report.child_report.title
                } due to 400 error: ${error.message || error}`
              );
              continue; // Skip this report and continue with the next one
            } else {
              throw error; // Re-throw if it's not a 400 error
            }
          }
        }
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(
        zipBlob,
        `All_Dashboards_${
          selectedDistrict.name
        }_${selectedYear}_${new Date().toLocaleDateString()}.zip`
      );

      toggleModal(); // Close modal when all API calls are complete
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setDownloadState({
        isDownloading: false,
        showErrorModal: true,
        errorDetails: error.message || "An unknown error occurred",
      });
    }
  }, [
    menuItems,
    selectedDistrict.irn,
    selectedDistrict.name,
    selectedYear,
    toggleModal,
  ]);

  return (
    <>
      <Button
        variant="primary"
        onClick={toggleModal}
        className="extractBtn"
        title="Download All Reports"
      >
        <FontAwesomeIcon icon={faFileExport} />
      </Button>
      <Modal show={show} onHide={toggleModal} className="exportModal">
        <Modal.Header closeButton>
          <Modal.Title>Download All Dashboard Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to download all dashboard reports?</p>
          <p>
            This action will download all reports as individual folders
            containing CSV files inside a single ZIP file. Please note that this
            process may take a few minutes depending on the file sizes and
            number of reports.
          </p>
          <p>
            <strong>
              Please note: reports without current data will not be downloaded
            </strong>
          </p>
          {downloadState.isDownloading && (
            <div className="loading-container">
              <Loading type="bubbles" color="#007bff" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={downloadAllReportsAsZip}
            disabled={downloadState.isDownloading}
          >
            {downloadState.isDownloading ? (
              <Loading type="bubbles" color="#fff" width={20} height={20} />
            ) : (
              "Download"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal
        show={downloadState.showErrorModal}
        onHide={() =>
          setDownloadState((prevState) => ({
            ...prevState,
            showErrorModal: false,
          }))
        }
        errorCode={500}
        errorMessage={`Failed to download the following reports: ${downloadState.errorDetails}`}
      />
    </>
  );
};

export default React.memo(DownloadAllDashMaster);
