import React from "react";
import "./Logo.css";
import header_logo from "./header_logo.png";

const Logo = () => {
  return (
    <div className="header-container">
      <img src={header_logo} className="header_image" alt="Crosscheck logo" />
    </div>
  );
};

export default Logo;
