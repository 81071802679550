import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Admin from "./components/Admin/Admin";
// import SqlQuery from "./components/Admin/SqlQuery";
import Blur from "./components/Blur/Blur";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Chat from "./components/Chat/Chat";
import Dashboard from "./components/Dashboard/Dashboard";
import DataTable from "./components/DataTable/DataTable";
import EditUser from "./components/EditUser/EditUser";
import Extract from "./components/Extract/Extract";
import ImportData from "./components/ImportData/ImportData";
import Load from "./components/Load/Load";
import Login from "./components/Login/Login";
import LogoutButton from "./components/Logout/Logout";
import MergedFiles from "./components/MergedFiles/MergedFiles";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ReleaseNotes from "./components/ReleaseNotes/ReleaseNotes";
import UpdatePassword from "./components/UpdatePassword/UpdatePassword";
import UserManagement from "./components/UserManagement/UserManagement";

// Help Documentation Links
import Introduction from "./components/HelpDocumentation/Introduction";
import HelpChecks from "./components/HelpDocumentation/HelpChecks";
import HelpDashboards from "./components/HelpDocumentation/HelpDashboards";
import HelpFunctions from "./components/HelpDocumentation/HelpFunctions";
import HelpImport from "./components/HelpDocumentation/HelpImport";
import HelpMergedFiles from "./components/HelpDocumentation/HelpMergedFiles";
import HelpUserManagement from "./components/HelpDocumentation/HelpUserManagement";
import HelpWelcome from "./components/HelpDocumentation/HelpWelcome";
import ReportList from "./components/ReportList/ReportList";
// Routes
import Layout from "./routes/Layout";

// Global variables and context providers
import { AuthProvider } from "./context/AuthProvider";
import { BlurProvider } from "./context/BlurContext";
import { DistrictProvider } from "./components/Breadcrumbs/Breadcrumbs";
import { SyncReportsProvider } from "./context/SyncReportsProvider";
import { UserManagementProvider } from "./context/UserManagementProvider";
import { AdminProvider } from "./context/AdminProvider";

// Styles
import "./App.css";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  const onLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    setAuthenticated(false);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      setAuthenticated(true);
    }

    // Attach event listener to handle browser window close
    window.addEventListener("beforeunload", onLogout);

    // Cleanup event listener
    return () => {
      window.removeEventListener("beforeunload", onLogout);
    };
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <DistrictProvider>
          <SyncReportsProvider>
            <UserManagementProvider>
              <BlurProvider>
                <AdminProvider>
                  <div>
                    <Routes>
                      {authenticated ? (
                        // All other routes here
                        <Route path="/" element={<ProtectedRoute />}>
                          <Route path="/" element={<Layout />}>
                            <Route index element={<Load />} />
                            <Route
                              path="dashboard/:menuId"
                              element={<Dashboard />}
                            />
                            <Route path="reportlist" element={<ReportList />} />
                            <Route
                              path="datatable/:reportId"
                              element={<DataTable />}
                            />
                            <Route
                              path="releasenotes/:reportId"
                              element={<ReleaseNotes />}
                            />
                            <Route
                              path="mergedfiles/:reportId"
                              element={<MergedFiles />}
                            />
                            <Route
                              path="extract/:reportId"
                              element={<Extract />}
                            />
                            <Route path="importdata" element={<ImportData />} />
                            <Route
                              path="usermanagement"
                              element={<UserManagement />}
                            />
                            <Route path="edituser" element={<EditUser />} />
                            <Route
                              path="updatepassword"
                              element={<UpdatePassword />}
                            />
                            <Route
                              path="changepassword"
                              element={<ChangePassword />}
                            />
                            <Route path="chat" element={<Chat />} />
                            <Route path="blur" element={<Blur />} />
                            <Route
                              path="helpdocumentation/introduction"
                              element={<Introduction />}
                            />
                            <Route
                              path="helpdocumentation/helpwelcome"
                              element={<HelpWelcome />}
                            />
                            <Route
                              path="helpdocumentation/helpdashboards"
                              element={<HelpDashboards />}
                            />
                            <Route
                              path="helpdocumentation/helpfunctions"
                              element={<HelpFunctions />}
                            />
                            <Route
                              path="helpdocumentation/helpchecks"
                              element={<HelpChecks />}
                            />
                            <Route
                              path="helpdocumentation/helpmergedfiles"
                              element={<HelpMergedFiles />}
                            />
                            <Route
                              path="helpdocumentation/helpimportdata"
                              element={<HelpImport />}
                            />
                            <Route
                              path="helpdocumentation/helpusermanagement"
                              element={<HelpUserManagement />}
                            />

                            {/* End Help Page Routes */}

                            <Route
                              path="logout"
                              element={<LogoutButton onLogout={onLogout} />}
                            />
                          </Route>
                          <Route path="admin" element={<Admin />} />
                          {/* <Route path="sqlquery" element={<SqlQuery />} /> */}
                        </Route>
                      ) : (
                        <Route
                          path="*"
                          element={
                            <Login onLogin={() => setAuthenticated(true)} />
                          }
                        />
                      )}
                    </Routes>
                  </div>
                </AdminProvider>
              </BlurProvider>
            </UserManagementProvider>
          </SyncReportsProvider>
        </DistrictProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
