import React from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditMenu = ({
  selectedMenu,
  handleEditMenu,
  handleSaveMenu,
  setEditMenu,
  setSelectedMenu,
  setIconModalShow,
}) => {
  return (
    <div className="edit-menu-container">
      <h2>{selectedMenu.title || "New Menu Item"}</h2>
      <Form>
        <Form.Group className="mb-2">
          <Form.Label>Menu Title</Form.Label>
          <Form.Control
            type="text"
            value={selectedMenu.title || ""}
            onChange={(e) => handleEditMenu("title", e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Menu Type</Form.Label>
          <Form.Select
            value={selectedMenu.menu_type || ""}
            onChange={(e) => handleEditMenu("menu_type", e.target.value)}
          >
            <option value="">Select Menu Type</option>
            <option value="reports">Reports</option>
            <option value="notes">Release Notes</option>
            <option value="dashboard">Dashboard</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Sort By</Form.Label>
          <Form.Control
            type="number"
            value={selectedMenu.sort_by || ""}
            onChange={(e) => handleEditMenu("sort_by", e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Icon</Form.Label>
          <Button variant="outline-info" onClick={() => setIconModalShow(true)}>
            <FontAwesomeIcon
              icon={
                selectedMenu.icon_active
                  ? `fas fa-${selectedMenu.icon_active}`
                  : "question"
              }
            />
          </Button>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>More Info</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={selectedMenu.notes || ""}
            onChange={(e) => handleEditMenu("notes", e.target.value)}
          />
        </Form.Group>
      </Form>
      <div className="footer">
        <Button
          variant="secondary"
          onClick={() => {
            setEditMenu(false);
            setSelectedMenu(null);
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveMenu}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditMenu;
