import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Maintenance.css";

const Maintenance = ({ show }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="modal-fullscreen"
    >
      <Modal.Body className="maintenance-body">
        <div className="maintenance-content">
          <h1>Scheduled Maintenance Notice</h1>
          <p>
            CrossCheck will be temporarily unavailable starting August 26th for
            approximately two weeks.
          </p>
          <p>
            We appreciate your understanding and patience as we conduct these
            essential updates in preparation for the 2025 school year.
          </p>

          <Button
            variant="danger"
            onClick={() => (window.location.href = "/logout")}
          >
            Logout
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Maintenance;
