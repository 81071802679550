import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconModal = ({ show, onHide, onSelect }) => {
  const icons = [
    "table-cells",
    "table",
    "table-cells-large",
    "award",
    "file",
    "file-lines",
    "download",
    "upload",
    "times",
    "info",
    "chevron-right",
    "chevron-left",
    "chevron-up",
    "chevron-down",
    "dollar-sign",
    "info-circle",
    "database",
    "edit",
    "trash",
    "save",
    "calendar",
    "clock",
    "user-friends",
    "user",
    "cog",
    "envelope",
    "refresh",
    "plus",
    "question",
    "exclamation",
    "check",
    "times-circle",
    "file-import",
    "file-export",
    "graduation-cap",
    "book",
    "chalkboard",
    "pencil-alt",
    "school",
    "phone",
    "user-graduate",
    "paperclip",
    "clipboard",
    "briefcase",
    "pen",
    "highlighter",
    "home",
    "search",
    "bars",
    "angle-double-right",
    "angle-double-left",
    "angle-double-up",
    "angle-double-down",
  ];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Icon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="icon-grid">
          {icons.map((icon) => (
            <div
              key={icon}
              className="icon-item"
              onClick={() => onSelect(`fas fa-${icon}`)}
            >
              <FontAwesomeIcon icon={["fas", icon]} />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IconModal;