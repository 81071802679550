import React, { useState, useContext } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnModal from "./ColumnModal";
import { AdminContext } from "../../context/AdminProvider";
import "./Admin.css";
import SqlQuery from "./SqlQuery";

const EditReportDashboard = ({
  editReport,
  setEditReport,
  handleBackToMenu,
  selectedMenu,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState({});
  const { saveOrEditReport, saveColumn, getData } = useContext(AdminContext);
  const [showQueryModal, setShowQueryModal] = useState(false);

  const handleSaveReport = async () => {
    console.log("Selected Menu at the time of saving:", selectedMenu);

    if (!editReport.menu_id && selectedMenu?.menu_id) {
      editReport.menu_id = selectedMenu.menu_id;
      console.log("Assigned Selected Menu ID to report:", selectedMenu.menu_id);
    } else if (!editReport.menu_id) {
      console.error(
        "Menu ID is not set, and selectedMenu is null:",
        selectedMenu
      );
      alert("Please select a menu before saving the report.");
      return; // Prevent save if menu_id is not set
    }

    console.log("Final Report Object to be saved:", editReport);

    await saveOrEditReport(editReport);
    await getData(selectedMenu?.menu_id);
    handleBackToMenu();
  };

  const handleOpenModal = (column) => {
    setSelectedColumn(column);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedColumn({});
    setShowModal(false);
  };

  // Fields are disabled if the report is not published
  const isDisabled = editReport?.publish === 1;

  return (
    <div className="edit-menu-container">
      <Form>
        <div className="form-row">
          <div className="col-md-3 mb-2">
            <Form.Label>Report Title</Form.Label>
            <Form.Control
              type="text"
              value={editReport.title || ""}
              onChange={(e) =>
                setEditReport({ ...editReport, title: e.target.value })
              }
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-2 mb-1">
            <Form.Label>Title Background Color</Form.Label>
            <Form.Select
              name="color"
              value={editReport.menu_bgcolor || ""}
              onChange={(e) =>
                setEditReport({
                  ...editReport,
                  menu_bgcolor: e.target.value,
                })
              }
              disabled={isDisabled}
            >
              <option value="">White</option>
              <optgroup label="Regular">
                <option value="alert-primary">Blue</option>
                <option value="alert-secondary">Gray</option>
                <option value="alert-success">Green</option>
                <option value="alert-danger">Red</option>
                <option value="alert-warning">Orange</option>
                <option value="alert-info">Cyan</option>
                <option value="alert-light">Light</option>
                <option value="alert-dark">Dark</option>
              </optgroup>
              <optgroup label="Bold">
                <option value="bg-primary">Blue</option>
                <option value="bg-secondary">Gray</option>
                <option value="bg-success">Green</option>
                <option value="bg-danger">Red</option>
                <option value="bg-warning">Orange</option>
                <option value="bg-info">Cyan</option>
                <option value="bg-light">Light</option>
                <option value="bg-dark">Dark</option>
              </optgroup>
            </Form.Select>
          </div>
          <div className="col-md-2 mb-1">
            <Form.Label>Title Text Color</Form.Label>
            <Form.Select
              name="color"
              value={editReport.menu_color || ""}
              onChange={(e) =>
                setEditReport({ ...editReport, menu_color: e.target.value })
              }
              disabled={isDisabled}
            >
              <option value="">Default</option>
              <option value="text-primary">Blue</option>
              <option value="text-muted">Gray</option>
              <option value="text-success">Green</option>
              <option value="text-danger">Red</option>
              <option value="text-warning">Orange</option>
              <option value="text-info">Cyan</option>
              <option value="text-white">White</option>
            </Form.Select>
          </div>
        </div>
        <div className="form-group">
          <Form.Label>More Info</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            className="edit_more_info"
            value={editReport.more_info || ""}
            onChange={(e) =>
              setEditReport({ ...editReport, more_info: e.target.value })
            }
            disabled={isDisabled}
          />
        </div>
        <div className="form-group">
          <Button
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => handleOpenModal({})}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
          <Form.Label>Columns</Form.Label>
          <Table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Badge/File</th>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {editReport.columns?.map((column, index) => (
                <tr key={index} draggable="true">
                  <td>{column.sort_by}</td>
                  <td>
                    <span className="badge badge-secondary">
                      {column.badge}
                    </span>
                  </td>
                  <td style={{ width: "40%" }}>{column.title}</td>
                  <td className="nowrap">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleOpenModal(column)}
                      title="Edit Column"
                      disabled={isDisabled}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                    {!column.confirmColDelete ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          /* logic to confirm delete column */
                        }}
                        title="Delete Column"
                        disabled={isDisabled}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            /* logic to cancel delete column */
                          }}
                          title="Cancel Delete"
                          disabled={isDisabled}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            /* logic to delete column */
                          }}
                          title="Confirm Delete"
                          disabled={isDisabled}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="form-group">
          <Form.Label>Report Query</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className="code"
            value={editReport.sql || ""}
            disabled={isDisabled}
            onChange={(e) =>
              setEditReport({ ...editReport, sql: e.target.value })
            }
          />
          <Button
            variant="primary"
            id="custom-query-button"
            onClick={() => setShowQueryModal(true)}
          >
            Send Query to Editor
          </Button>

          <SqlQuery
            show={showQueryModal}
            handleClose={() => setShowQueryModal(false)}
            initialQuery={editReport.sql}
            reportTitle={editReport.title}
          />
        </div>
        <div className="footer">
          <Button variant="secondary" onClick={handleBackToMenu}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveReport}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Form>
      <ColumnModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedColumn={selectedColumn}
        saveColumn={saveColumn}
      />
    </div>
  );
};

export default EditReportDashboard;
