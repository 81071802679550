const ExcludedMenuTitles = () => {
  return [
    "Import Data",
    "Refresh Reports",
    "Sign Off",
    "Toggle Expand",
    "User Management",
    "Welcome",
    "crosscheck",
    // Release notes will be excluded in addition to the above titles on an as-needed basis in the other component
  ];
};

export default ExcludedMenuTitles;
