import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./help.css";
import welcomeimg1 from "./HelpImgs/welcome1.png";
import welcomeimg2 from "./HelpImgs/welcome2.png";
import welcomeimg3 from "./HelpImgs/welcome3.png";
import welcomeimg4 from "./HelpImgs/welcome4.png";
import welcomeimg5 from "./HelpImgs/welcome5.png";

const HelpWelcome = () => {
  return (
    <div className="help-container">
      <Row className="justify-content-md-center">
        <Col lg={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Welcome Page</h1>
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Once a user has logged in to CrossCheck, a synchronization
                    will occur between CrossCheck and the data collector.
                  </p>
                </Col>
                <Col md={7}>
                  <img
                    src={welcomeimg1}
                    alt="sync screen in progress"
                    className="help-img-fluid full-width-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Synchronization can produce a few possible outcomes
                    depending on the status of the data. If there is no new data
                    in the data collector, nothing will be synced.
                  </p>
                </Col>
                <Col md={7}>
                  <img
                    src={welcomeimg2}
                    alt="sync completed"
                    className="help-img-fluid full-width-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    When new files are found in the data collected and loaded
                    into CrossCheck, you will see a list of the records created.
                    Only valid records are brought into CrossCheck. Fatal or Dep
                    records are ignored.
                  </p>
                </Col>
                <Col md={7}>
                  <img
                    src={welcomeimg3}
                    alt="import with records displayed"
                    className="help-img-fluid full-width-img"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={5}>
                  <p className="help-paragraph">
                    Once the sync is complete, checks, dashboards, and merged
                    files are updated. You will see the status of the report
                    population in the pop-up window:
                  </p>
                </Col>
                <Col md={7}>
                  <img
                    src={welcomeimg4}
                    alt="Report Build Status"
                    className="help-img-fluid full-width-img"
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col md={5}>
                  <p className="help-paragraph">
                    If desired, the report population window may be closed. Once
                    the window is closed, spinners will appear next to reports
                    and menu items as they load. This will allow a user to view
                    reports as they are loaded.
                  </p>
                </Col>
                <Col md={7}>
                  <img
                    src={welcomeimg5}
                    alt="Report Build Status"
                    className="help-img-fluid full-width-img"
                    style={{
                      width: "50%",
                      position: "relative",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <p className="help-paragraph">
                On rare occasions, a sync will not occur as expected or fail.
                There are a few potential causes. Here are some troubleshooting
                tips to correct the issue:
              </p>
              <ul className="help-list">
                <li className="help-list-item">
                  A new version of a collection request on the data collector
                  will cause the previously prepared files to be dropped. When
                  the sync runs it reverts to the previous files.
                </li>
                <li className="help-list-item">
                  Moving from the data collector to CrossCheck too quickly. It
                  takes a minute or so for the files to be generated in the data
                  collector once preparation finishes. If a sync is attempted
                  before this process is complete, it will fail.
                </li>
                <li className="help-list-item">
                  Cancelling a collection that is not submitted causes old data
                  to be synced the next time the CrossCheck sync runs.
                </li>
                <li className="help-list-item">
                  Cancelling a collection that has been submitted causes the
                  data in CrossCheck to be out of sync.
                </li>
                <li className="help-list-item">
                  Resolution for all sync issues is to do a new collect and
                  prepare on the collection request in the data collector, wait
                  5 minutes for the prepare to complete fully, and then let
                  CrossCheck synchronize again.
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpWelcome;