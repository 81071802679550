// eslint-disable-next-line no-unused-vars
import React from "react";

const handlePrintSingleReport = async (reportId, reportData) => {
  try {
    const selectedReportData = reportData[reportId];
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      `<html><head><title>${selectedReportData.report.title}</title>
      <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
      }
      h1, h2 {
        margin-bottom: 10px;
      }
      h5 {
        margin-top: 5px;
        margin-bottom: 15px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      th {
        background-color: #f2f2f2;
      }
      thead th {
        background-color: #e0e0e0;
        font-weight: bold;
      }
      .report-section {
        margin-bottom: 40px;
      }
      .report-container {
        padding: 20px;
      }
      @media print {
        body {
          margin: 0;
        }
        .report-container {
          padding: 0;
        }
      }
    </style>
      </head>
      <body>    
      <h1>${selectedReportData.report.title}</h1>
      <h5>Report generated on: ${new Date().toLocaleString()}</h5>
      <h5>More information: ${
        selectedReportData.report.more_info ||
        "No information available for this report."
      }</h5>
      <table>
        <thead>
          <tr>
            ${selectedReportData.report.columns
              .map((column) => `<th>${column.title}</th>`)
              .join("")}
          </tr>
        </thead>
        <tbody>
<tbody>
  ${selectedReportData.data
    .map(
      (row) =>
        `<tr>${selectedReportData.report.columns
          .map(
            (column) =>
              `<td>${
                row.fields[column.data_col_name] === null
                  ? ""
                  : column.title.includes("% of Time") ||
                    column.title.includes("Percent")
                  ? parseFloat(row.fields[column.data_col_name]).toFixed(2)
                  : row.fields[column.data_col_name]
              }</td>`
          )
          .join("")}</tr>`
    )
    .join("")}
</tbody>

      </table>
      </body></html>`
    );
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 500);
  } catch (error) {
    console.error(error);
  }
};

export default handlePrintSingleReport;
