
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./help.css";
import import1 from "./HelpImgs/import1.png";

const HelpImportData = () => {
  return (
    <div className="help-container">
      <Row>
        <Col md={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Import Data</h1>
              <p className="help-paragraph">
                This page is used when a district is first added to CrossCheck
                by an ITC. Historical files must be added once to generate the
                3-year look-back files and special education checks.
              </p>
              <p className="help-paragraph">
                When CrossCheck is set up for a district, any collection still
                available on the “Collection Request” tab of the data collector
                will be automatically synchronized. When a collection within the
                past 3 years has been archived, it needs to be imported manually.
              </p>
              <p className="help-paragraph">
                To import the historical files, use the data collector to
                generate the archive files for the FY20 and FY21 End of Year
                Student Collections.
              </p>
              <img
                src={import1}
                className="help-img-fluid"
                alt="Archived files and reports from data collector"
                style={{ width: "90%", height: "auto", margin: "0 auto" }}
              />
              <p className="help-paragraph">
                Import the Zip file into CrossCheck. After historical files are
                loaded, you will see the IEP/ETR merged file rows generate as
                well as the special education checks.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpImportData;
