// import React, { useCallback, useState, useEffect } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import axios from "../../api/axios";

// function ProtectedRoute() {
//   const isAuthenticated = !!localStorage.getItem("access_token"); // For example's sake, using localStorage

//   const [isRefreshingToken, setIsRefreshingToken] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const refreshToken = useCallback(async () => {
//     // console.log("Attempting to refresh token...");
//     const refreshToken = localStorage.getItem("refresh_token");
//     if (!refreshToken) {
//       console.error("No refresh token available");
//       setIsLoggedIn(false);
//       return;
//     }

//     try {
//       setIsRefreshingToken(true);
//       const response = await axios.post("/refresh");
//       if (response.status === 200) {
//         localStorage.setItem("access_token", response.data.access_token);
//         localStorage.setItem("refresh_token", response.data.refresh_token);
//         setIsLoggedIn(true);
//       }
//     } catch (error) {
//       console.error("Error refreshing token:", error);
//       setIsLoggedIn(false);
//     } finally {
//       setIsRefreshingToken(false);
//     }
//   }, []);

//   useEffect(() => {
//     if (!isAuthenticated) {
//       refreshToken(); // Refresh token if not authenticated
//     }
//   }, [isAuthenticated, refreshToken]);

//   if (isAuthenticated) {
//     return <Outlet />; // If authenticated, render the child routes
//   }

//   // If not authenticated, redirect to login
//   return <Navigate to="/login" replace />;
// }

// export default ProtectedRoute;

//Original code
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const isAuthenticated = !!localStorage.getItem("access_token"); // For example's sake, using localStorage

  if (isAuthenticated) {
    return <Outlet />;  // If authenticated, render the child routes
  }

  // If not authenticated, redirect to login
  return <Navigate to="/login" replace />;
}

export default ProtectedRoute;
