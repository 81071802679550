import React, { useContext, useEffect, useState } from "react";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationCircle, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Spinner } from "react-bootstrap";

const formatDateString = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString;
  }
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

function Extract({ reportId }) {
  const [buildExport, setBuildExport] = useState(false);
  const [exportComplete, setExportComplete] = useState(false);
  const [exportError, setExportError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  const { reports, selectedYear, runReport, findReportById } =
    useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();

  useEffect(() => {
    const fetchedReport = reports[reportId];
    if (fetchedReport && fetchedReport.report) {
      setTitle(fetchedReport.report.title || "");
    }
  }, [reportId, reports]);

  const resetExportState = () => {
    setBuildExport(false);
    setExportComplete(false);
    setExportError(null);
  };

  const runExport = async () => {
    setBuildExport(true);
    setExportComplete(false);
    setExportError(null);

    try {
      const reportToUpdate = findReportById(reportId);
      if (reportToUpdate) {
        await runReport(reportToUpdate);
      }

      const response = await axios.post(
        `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`
      );

      const csvContent = generateCSVContent(response.data);
      downloadCSV(csvContent, title);

      setBuildExport(false);
      setExportComplete(true);
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setBuildExport(false);
      setExportError(error);
    }
  };

  const generateCSVContent = (data) => {
    const cr = "\r\n";
    let csvContent = data.headers + cr;
    data.data.forEach((row) => {
      const formattedRow = row.row
        .split(",")
        .map((cell) => {
          return cell.match(/\d{4}-\d{2}-\d{2}/)
            ? formatDateString(cell)
            : cell;
        })
        .join(",");
      csvContent += formattedRow + cr;
    });
    return csvContent;
  };

  const downloadCSV = (csvContent, title) => {
    const today = new Date();
    const dateTime = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}-${today.getHours()}-${today.getMinutes()}`;
    const txtFile = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(txtFile);
    link.download = `${title}_${dateTime}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleModal = () => {
    if (!showModal) {
      resetExportState();
    }
    setShowModal(!showModal);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={toggleModal}
        className="extractBtn"
        title="Download Detail Report"
      >
        <FontAwesomeIcon icon={faFileExport} />
      </Button>
      <Modal show={showModal} onHide={toggleModal} className="exportModal">
        <Modal.Header closeButton>
          <Modal.Title>Export Builder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buildExport ? (
            <div>
              <Spinner animation="border" variant="primary" /> Exporting...
            </div>
          ) : exportComplete ? (
            <div>
              <FontAwesomeIcon icon={faCheck} color="green" /> Export Complete
            </div>
          ) : exportError ? (
            <div>
              <FontAwesomeIcon icon={faExclamationCircle} color="red" /> Export
              Failed
            </div>
          ) : (
            <Button variant="primary" onClick={runExport}>
              Click to Export
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Extract;
