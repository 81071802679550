import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./help.css";
import dashhelp1 from "./HelpImgs/dashhelp1.png";
import dashhelp2 from "./HelpImgs/dashhelp2.png";
import dashhelp3 from "./HelpImgs/dashhelp3.png";
import dashhelp4 from "./HelpImgs/dashhelp4.jpg";

const HelpDashboard = () => {
  return (
    <div className="help-container">
      <Row className="justify-content-md-center">
        <Col lg={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body text-center">
              <h1 className="help-title">Dashboards</h1>
            </Card.Body>
            <Row>
              <Col md={4}>
                <Card.Body className="help-card-body">
                  <p className="help-paragraph">
                    District/Building dashboards are separated by category:
                  </p>
                  <ul className="help-list">
                    <li className="help-list-item">
                      District sections have a blue title bar.
                    </li>
                    <li className="help-list-item">
                      Building sections have a green title bar.
                    </li>
                    <li className="help-list-item">
                      Staff sections have an orange title bar.
                    </li>
                    <li className="help-list-item">
                      Enrollment data is pulled from the Enrollment Report – Current
                      from the Data Collector.
                    </li>
                    <li className="help-list-item">
                      Staff data is generated from the CI/CK files.
                    </li>
                  </ul>
                </Card.Body>
              </Col>
              <Col md={8}>
                <Card.Img
                  className="help-img-fluid"
                  src={dashhelp1}
                  alt="district/building dashboard collection dates"
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Col>
            </Row>
            <Card.Body className="help-card-body">
              <p className="help-paragraph">
                An “as of” date is shown at the top of the dashboard to indicate
                when the data was last collected. It is important to note that
                these dates are not the date the sync happened but are the true
                date the last collection was prepared and/or submitted in the
                data collector.
              </p>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h2 className="help-title">Dashboard Features:</h2>
              <Row>
                <Col md={6}>
                  <ul className="help-list">
                    <li className="help-list-item">
                      To the right (for most sections) are three icons:
                    </li>
                    <ul className="help-list">
                      <li className="help-list-item">
                        Click the yellow info circle icon to see more information.
                      </li>
                      <li className="help-list-item">
                        Click the green printer icon to print this section.
                      </li>
                      <li className="help-list-item">
                        Click the blue export button to export the data behind the
                        numbers.
                      </li>
                    </ul>
                  </ul>
                </Col>
                <Col md={6}>
                  <Card.Img
                    className="help-img-fluid"
                    src={dashhelp2}
                    alt="icons on dashboards"
                    style={{ width: "100%", margin: "0 auto" }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Row>
              <Col md={6}>
                <Card.Body className="help-card-body">
                  <ul className="help-list">
                    <li className="help-list-item">
                      After clicking the export arrow, the next page provides a blue
                      link to export the data. This box will also include more
                      information for the detailed report.
                    </li>
                    <li className="help-list-item">Click the export box.</li>
                  </ul>
                </Card.Body>
              </Col>
              <Col md={6}>
                <Card.Img
                  className="help-img-fluid"
                  src={dashhelp3}
                  alt="export report button"
                  style={{ width: "90%", margin: "0 auto" }}
                />
              </Col>
            </Row>
            <Card.Body className="help-card-body">
              <ul className="help-list">
                <li className="help-list-item">
                  Depending on the browser and setup, the file will be made
                  available to save or open in a spreadsheet application such as
                  Excel.
                </li>
                <li className="help-list-item">
                  The dashboard section "District Overview" does not have a
                  download report.
                </li>
              </ul>
            </Card.Body>
            <Card.Img
              className="help-img-fluid"
              src={dashhelp4}
              alt="save or open in excel"
              style={{ width: "40%", margin: "0 auto 1rem" }}
            />
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <p className="help-paragraph">Graduation dashboards include:</p>
              <ul className="help-list">
                <li className="help-list-item">Overview of Cohorts</li>
                <li className="help-list-item">Seals</li>
                <li className="help-list-item">Competency</li>
                <li className="help-list-item">Readiness</li>
                <li className="help-list-item">Who Counts Against Rate</li>
                <li className="help-list-item">Dual Credits</li>
                <li className="help-list-item">Diploma Types</li>
                <li className="help-list-item">College Ready Seals</li>
              </ul>
              <p className="help-paragraph">
                Graduation dashboards are published and unpublished as the
                reporting for graduates begins and ends. The CrossCheck team
                indicates in the release notes and the titles what data is for
                what graduation cohort. When a dashboard is unpublished, it will
                show as a blank spot on the dashboard page.
              </p>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <p className="help-paragraph">
                There are also dashboard areas for:
              </p>
              <ul className="help-list">
                <li className="help-list-item">Staff</li>
                <li className="help-list-item">Career Tech</li>
                <li className="help-list-item">Organizational Information</li>
                <li className="help-list-item">
                  Dropout Prevention & Recovery Schools
                </li>
                <ul className="help-list">
                  <li className="help-list-item">
                    Dropout Prevention & Recovery Schools show the extended
                    graduation rate for these organizations.
                  </li>
                </ul>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpDashboard;
