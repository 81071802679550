import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import login_logo from "./login_logo.png";
import "./Login.css";

const LoginPage = ({ onLogin }) => {
  const [userInput, setUserInput] = useState({ username: "", password: "" });
  const [loginStatus, setLoginStatus] = useState({
    errorMessage: "",
    loggingIn: false,
    showAlert: false,
    showPassword: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowClose = () => {
      // Clear localStorage and other session data
      [
        "access_token",
        "crosscheck_role",
        "display_name",
        "first_name",
        "last_name",
        "login_time",
        "refresh_token",
      ].forEach((item) => localStorage.removeItem(item));
    };

    window.addEventListener("beforeunload", handleWindowClose);
    return () => window.removeEventListener("beforeunload", handleWindowClose);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginStatus((prev) => ({ ...prev, loggingIn: true }));

    try {
      const { username, password } = userInput;
      const response = await axios.get("login", {
        auth: { username, password },
      });

      if (response.status === 200) {
        [
          "access_token",
          "crosscheck_role",
          "display_name",
          "first_name",
          "last_name",
          "refresh_token",
        ].forEach((key) => localStorage.setItem(key, response.data[key]));
        localStorage.setItem("login_time", new Date().getTime());
        onLogin();
        navigate("/");
      }
    } catch (err) {
      console.error(err);
      setLoginStatus({
        errorMessage: "Login failed! Please check your credentials.",
        loggingIn: false,
        showAlert: true,
        showPassword: false,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setLoginStatus((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  return (
    <Container className="login-container">
      {loginStatus.showAlert && (
        <Alert
          variant="danger"
          onClose={() =>
            setLoginStatus((prev) => ({ ...prev, showAlert: false }))
          }
          dismissible
        >
          {loginStatus.errorMessage}
        </Alert>
      )}
      <img src={login_logo} className="login-logo" alt="login logo" />
      <Form onSubmit={handleLogin}>
        <Form.Group className="mb-5" controlId="formUsername">
          <Form.Label>Username:</Form.Label>
          <Form.Control
            name="username"
            type="text"
            value={userInput.username}
            placeholder="Username"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-5" controlId="formPassword">
          <Form.Label>Password:</Form.Label>
          <div className="password-input">
            <Form.Control
              name="password"
              type={loginStatus.showPassword ? "text" : "password"}
              value={userInput.password}
              placeholder="Password"
              onChange={handleChange}
            />
            <span className="eye-icon" onClick={togglePasswordVisibility}>
              <FontAwesomeIcon
                icon={loginStatus.showPassword ? faEyeSlash : faEye}
              />
            </span>
          </div>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={loginStatus.loggingIn}
          className="loginBtn"
        >
          {loginStatus.loggingIn ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Login"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default LoginPage;
