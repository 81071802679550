import React from "react";
import { Table, Button, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DashboardReports = ({
  viewReports,
  handleBackToMenu,
  handleEditReport,
  handleEditChildReport,
  handleTogglePublish,
  handleDeleteReport,
}) => {
  return (
    <div className="reports-table-container">
      <h2>{viewReports.title}</h2>
      <Button variant="secondary" onClick={handleBackToMenu}>
        Back to Menu
      </Button>
      <Alert variant="info">
        A Parent Report can be modified by clicking on Parent Report in the
        Dashboard Title column. Parent reports that contain child reports can be
        edited by clicking on the edit report button in the Actions column.
      </Alert>
      <Table striped bordered hover className="reports-table">
        <thead>
          <tr>
            <th>Dashboard Title (Parent Report)</th>
            <th>More Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {viewReports.reports?.map(
            (report, index) =>
              !report.parent_report_id && (
                <tr key={index} className="pointer">
                  <td
                    className="pointer"
                    onClick={() => handleEditReport(report)}
                  >
                    {report.title}
                  </td>
                  <td>
                    {report.more_info && report.more_info.length > 25
                      ? `${report.more_info.substring(0, 25)}...`
                      : report.more_info || ""}
                  </td>
                  <td className="nowrap">
                    <Button
                      variant="info"
                      size="sm"
                      title="Edit Report"
                      onClick={() => handleEditReport(report)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                    <Button
                      variant={report.publish ? "primary" : "danger"}
                      size="sm"
                      onClick={() =>
                        handleTogglePublish(report, !report.publish)
                      }
                      title={
                        report.publish
                          ? "Click To Unpublish Report"
                          : "Click To Publish Report"
                      }
                    >
                      <FontAwesomeIcon
                        icon={report.publish ? "eye" : "eye-slash"}
                      />
                    </Button>

                    <Button
                      variant="primary"
                      size="sm"
                      title={report.child_report?.title || ""}
                      onClick={() => handleEditChildReport(report.child_report)}
                    >
                      <FontAwesomeIcon icon="file" />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      title="Delete Report"
                      onClick={() => handleDeleteReport(report)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </td>
                </tr>
              )
          ) || (
            <tr>
              <td colSpan="3" className="text-center">
                No reports available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DashboardReports;
