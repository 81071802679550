import React from "react";
import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notes = ({
  viewReports,
  handleBackToMenu,
  handleEditReport,
  deleteReport,
  handleTogglePublish,
}) => {
  return (
    <div className="edit-menu-container">
      <h2>{viewReports.title}</h2>
      <Button variant="secondary" onClick={handleBackToMenu}>
        Back to Menu
      </Button>
      <Table striped bordered hover className="notes-table">
        <thead className="notesHeaders">
          <tr>
            <th>Release Note Menu Title (Shown on Nav)</th>
            <th> Release Note Title (Shown on the Report)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {viewReports.reports?.map((note, index) => (
            <tr key={index} className="pointer">
              {/* <td>{note.menu_title}</td>
              <td>{note.title}</td> */}
              {/* Use Onclick for the titles */}
              <td
                className="pointer"
                onClick={() => handleEditReport(note)}
              >
                {note.menu_title}
              </td>
              <td
                className="pointer"
                onClick={() => handleEditReport(note)}
              >
                {note.title}
              </td>
              <td className="nowrap">
                <Button
                  variant="info"
                  size="sm"
                  title="Edit Note"
                  onClick={() => handleEditReport(note)}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
                <Button
                  variant={note.publish ? "primary" : "danger"}
                  size="sm"
                  onClick={() => handleTogglePublish(note, !note.publish)}
                  title={
                    note.publish
                      ? "Click To Unpublish Release Note"
                      : "Click To Publish Release Note"
                  }
                >
                  <FontAwesomeIcon icon={note.publish ? "eye" : "eye-slash"} />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  title="Delete Note"
                  onClick={() => deleteReport(note)} // Use deleteReport here
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </td>
            </tr>
          )) || (
            <tr>
              <td colSpan="4" className="text-center">
                No notes available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Notes;
