import axios from "axios";

const BASE_URLS = {
  QA: "https://cc-api-qa.metasolutions.net",
  PRODUCTION: "https://cc-api.metasolutions.net",
};

const CONTENT_TYPE_HEADER = "application/json";
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

let isRefreshingToken = false;
let subscribers = [];

const getBaseUrl = () =>
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("qa")
    ? BASE_URLS.QA
    : BASE_URLS.PRODUCTION;

const getToken = () =>
  localStorage.getItem(
    isRefreshingToken ? REFRESH_TOKEN_KEY : ACCESS_TOKEN_KEY
  );

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": CONTENT_TYPE_HEADER,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Token refresh handling
const onTokenRefreshed = (access_token) => {
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

axiosInstance.interceptors.response.use(undefined, (error) => {
  const { config, response } = error;
  const originalRequest = config;

  if (response && response.status === 401 && !originalRequest._retry) {
    if (isRefreshingToken) {
      return new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(axios(originalRequest));
        });
      });
    }

    originalRequest._retry = true;
    isRefreshingToken = true;

    return new Promise((resolve, reject) => {
      refreshToken()
        .then(({ access_token }) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          originalRequest.headers["Authorization"] = "Bearer " + access_token;
          onTokenRefreshed(access_token);
          resolve(axios(originalRequest));
        })
        .catch((error) => {
          onLogout(); // Log out if refresh token fails
          reject(error);
        })
        .finally(() => {
          isRefreshingToken = false;
        });
    });
  }

  return Promise.reject(error);
});

async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }
  const response = await axiosInstance.post("/refresh", {
    token: refreshToken,
  });
  return response.data;
}

const onLogout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const setIsRefreshingToken = (value) => {
  isRefreshingToken = value;
};

export default axiosInstance;
