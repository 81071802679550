import React from 'react';
import { Modal, Button } from 'react-bootstrap';

// Error message mapping object
const errorMessages = {
  403: "Your session has expired, please log back in to continue.",
  404: "The requested resource could not be found. We are currently experiencing issues with this report. Please try again later. If the issue persists, please contact the system administrator.",
  500: "An internal server error occurred, please try again later. If the issue persists, please contact the system administrator.",
  default: "An error has occurred, please try again later. If the issue persists, please contact the system administrator.",
};

const ErrorModal = ({ show, onHide, errorCode, errorMessage, title }) => {
  // Parse the errorCode to an integer, in case it's a string
  const code = parseInt(errorCode, 10);

  const getErrorMessage = () => {
    return errorMessages[code] || errorMessages.default;
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title || 'Error'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Error Code {code}:</strong> {getErrorMessage()}
        </p>
        {errorMessage && (
          <p>
            <strong>Details:</strong> {errorMessage}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
