import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./help.css";
import merged1 from "./HelpImgs/merged1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const HelpMergedFiles = () => {
  return (
    <div className="help-container">
      <Row>
        <Col md={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Merged Files</h1>
              <Row>
                <Col md={6} className="text-center">
                  <Card.Img
                    src={merged1}
                    className="help-img-fluid help-merged-img"
                    alt="merged files/reports list"
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ul className="help-list">
                    <li className="help-list-item">
                      Blue circles{" "}
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{ color: "blue" }}
                      />{" "}
                      indicate the number of records in the file.
                    </li>
                    <li className="help-list-item">
                      FTE-001 Enhanced has added columns with translations for
                      IRNs and codes.
                    </li>
                    <li className="help-list-item">
                      Gifted Served lists all students served and includes
                      program code/course.
                    </li>
                    <li className="help-list-item">
                      Graduation Seals lists all codes being reported.
                    </li>
                    <li className="help-list-item">
                      Latest ETR & IEP Merged lists all students reported with a
                      disability with the students’ most recent IEP & ETR.
                    </li>
                    <li className="help-list-item">
                      Program Codes by Category breaks the GQ record into
                      categories such as RIMP, Seals, Gifted, etc.
                    </li>
                    <li className="help-list-item">
                      Staff Merged CI/CK and CU/CN.
                    </li>
                    <li className="help-list-item">
                      Staff/Course/Grades Merged CU/FR/CN.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpMergedFiles;
