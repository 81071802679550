import React, { useState, useContext } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotesModal from "./NotesModal"; // Assuming we'll be creating this modal for adding/editing notes
import { AdminContext } from "../../context/AdminProvider";
import "./Admin.css";

const EditReleaseNotes = ({
  editReport,
  setEditReport,
  handleBackToMenu,
  selectedMenu,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null); // Manage the selected note for editing
  const { saveOrEditReport, saveNote, getData, menu } =
    useContext(AdminContext);

  const handleSaveReport = async () => {
    if (!editReport.menu_id && selectedMenu?.menu_id) {
      editReport.menu_id = selectedMenu.menu_id;
    } else if (!editReport.menu_id) {
      alert("Please select a menu before saving the report.");
      return; // Prevent save if menu_id is not set
    }

    await saveOrEditReport(editReport);
    await getData(selectedMenu?.menu_id);
    handleBackToMenu();
  };

  const handleOpenModal = (note) => {
    setSelectedNote(note || {});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNote(null);
  };

  return (
    <div className="edit-menu-container">
      <Form>
        <div className="form-row">
          <div className="col-md-4 mb-2">
            <div className="editMenuTitle">
              <Form.Label>Menu Title</Form.Label>
              <Form.Control
                name="title"
                type="text"
                value={editReport.menu_title || ""}
                onChange={(e) =>
                  setEditReport({
                    ...editReport,
                    menu_title: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <Form.Label>Report Title</Form.Label>
            <Form.Control
              type="text"
              value={editReport.title || ""}
              onChange={(e) =>
                setEditReport({ ...editReport, title: e.target.value })
              }
            />
          </div>
          <div className="col-md-3 mb-2">
            <Form.Label>Sort</Form.Label>
            <Form.Control
              type="text"
              value={editReport.sort_by || ""}
              onChange={(e) =>
                setEditReport({ ...editReport, sort_by: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group">
          <Button
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => handleOpenModal({})}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
          <Form.Label>Notes</Form.Label>
          <Table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Check/Merge Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {editReport.notes?.length > 0 ? (
                editReport.notes.map((note, index) => (
                  <tr key={index}>
                    <td>{note?.sort_by || "-"}</td>
                    <td>{note?.menu_title || "-"}</td>
                    <td>{note?.report_title || "-"}</td>
                    <td>{note?.release_note || "-"}</td>
                    <td className="nowrap">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleOpenModal(note)}
                        title="Edit Note"
                      >
                        <FontAwesomeIcon icon="edit" />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        title="Delete Note"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this note?"
                            )
                          ) {
                            // Add your delete logic here
                          }
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No notes available.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="footer">
          <Button variant="secondary" onClick={handleBackToMenu}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveReport}>
            Save
          </Button>
        </div>
      </Form>
      <NotesModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedNote={selectedNote}
        saveNote={saveNote}
        menuItems={menu}
        selectedMenu={selectedMenu} // Pass selectedMenu here
      />
    </div>
  );
};

export default EditReleaseNotes;
