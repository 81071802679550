import React, { useState, useEffect } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@monaco-editor/react";
import { useTable } from "react-table";

const SqlQueryModal = ({ show, handleClose, initialQuery, reportTitle }) => {
  const [activeKey, setActiveKey] = useState(1); // Track the active tab
  const [queryTabs, setQueryTabs] = useState([
    { id: 1, query: "", showResult: false, name: reportTitle || "Query 1" },
  ]);

  const [persistedTabs, setPersistedTabs] = useState([]);

  useEffect(() => {
    if (initialQuery) {
      const initialTabs = [
        {
          id: 1,
          query: initialQuery,
          showResult: false,
          name: reportTitle || "Query 1",
        },
      ];
      setQueryTabs(initialTabs);
      setPersistedTabs(initialTabs);
    }
  }, [initialQuery, reportTitle]);

  useEffect(() => {
    if (!show && persistedTabs.length) {
      setQueryTabs(persistedTabs);
    }
  }, [show, persistedTabs]);

  const handleRunQuery = (tabId, e) => {
    e.preventDefault();
    const newTabs = queryTabs.map((tab) =>
      tab.id === tabId ? { ...tab, showResult: true } : tab
    );
    setQueryTabs(newTabs);
    setPersistedTabs(newTabs);
  };

  const handleAddTab = () => {
    const newTabId = queryTabs.length + 1;
    const newTabs = [
      ...queryTabs,
      { id: newTabId, query: "", showResult: false, name: `Query ${newTabId}` },
    ];
    setQueryTabs(newTabs);
    setActiveKey(newTabId);
    setPersistedTabs(newTabs);
  };

  const handleQueryChange = (tabId, value) => {
    const newTabs = queryTabs.map((tab) =>
      tab.id === tabId ? { ...tab, query: value } : tab
    );
    setQueryTabs(newTabs);
    setPersistedTabs(newTabs);
  };

  const handleClearQuery = (tabId) => {
    const newTabs = queryTabs.map((tab) =>
      tab.id === tabId ? { ...tab, query: "", showResult: false } : tab
    );
    setQueryTabs(newTabs);
    setPersistedTabs(newTabs);
  };

  const handleRemoveTab = (tabId) => {
    const newTabs = queryTabs.filter((tab) => tab.id !== tabId);
    setQueryTabs(newTabs);
    setActiveKey(queryTabs[0]?.id || 1);
    setPersistedTabs(newTabs);
  };

  const handleTabNameChange = (tabId, value) => {
    const newTabs = queryTabs.map((tab) =>
      tab.id === tabId ? { ...tab, name: value } : tab
    );
    setQueryTabs(newTabs);
    setPersistedTabs(newTabs);
  };

  const columns = [
    { Header: "#", accessor: "id" },
    { Header: "First Name", accessor: "firstName" },
    { Header: "Last Name", accessor: "lastName" },
    { Header: "Username", accessor: "username" },
  ];

  const data = [
    { id: 1, firstName: "Mark", lastName: "Otto", username: "@mdo" },
    { id: 2, firstName: "Jacob", lastName: "Thornton", username: "@fat" },
    { id: 3, firstName: "Larry", lastName: "Bird", username: "@twitter" },
  ];

  const QueryResultsTable = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data });

    return (
      <table
        {...getTableProps()}
        className="table table-striped table-bordered"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-width"
      size="xl"
      centered
    >
      <Modal.Header>
        <Modal.Title>SQL Query Editor</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="sql-query-wrapper">
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => {
              if (k === "add") {
                handleAddTab();
              } else {
                setActiveKey(k);
              }
            }}
            id="query-tabs"
            className="sql-query-tabs"
          >
            {queryTabs.map((tab) => (
              <Tab
                key={tab.id}
                eventKey={tab.id}
                title={
                  <div className="tab-title-container">
                    <input
                      type="text"
                      value={tab.name}
                      onChange={(e) =>
                        handleTabNameChange(tab.id, e.target.value)
                      }
                      className="tab-title-input"
                      style={{
                        width: `${Math.max(tab.name.length * 8, 100)}px`,
                      }}
                    />
                    {queryTabs.length > 1 && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="ml-2 pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveTab(tab.id);
                        }}
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    )}
                  </div>
                }
              >
                <div className="sql-query-editor">
                  <form onSubmit={(e) => handleRunQuery(tab.id, e)}>
                    <Editor
                      height="400px"
                      language="sql"
                      value={tab.query}
                      onChange={(value) => handleQueryChange(tab.id, value)} // Ensure the correct use of onChange
                      options={{
                        fontSize: 16,
                        minimap: { enabled: false },
                        wordWrap: "on", // Optional: Enable word wrapping
                        scrollBeyondLastLine: false, // Optional: Disable scrolling beyond last line
                      }}
                    />

                    <Button type="submit" className="mt-3">
                      Run Query
                    </Button>
                    <Button
                      onClick={() => handleClearQuery(tab.id)}
                      className="mt-3 ml-3"
                      variant="warning"
                    >
                      Clear Query
                    </Button>
                  </form>
                </div>

                {tab.showResult && (
                  <div className="sql-query-results">
                    <h3>Query Results</h3>
                    <QueryResultsTable columns={columns} data={data} />
                  </div>
                )}
              </Tab>
            ))}
            <Tab
              eventKey="add"
              title={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleAddTab}
            />
          </Tabs>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SqlQueryModal;