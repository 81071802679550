import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { NavDropdown } from "react-bootstrap";

const HelpLink = () => {
  const navigate = useNavigate();

  const handleHelpItemClick = (path) => {
    navigate(path);
  };

  return (
    <NavDropdown
      title={<FontAwesomeIcon icon={faQuestion} title="Help Documentation" />}
      id="help-documentation-dropdown"
      className="nav-dropdown"
    >
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/introduction")}
      >
        Introduction
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/helpwelcome")}
      >
        Welcome
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/helpdashboards")}
      >
        Dashboards
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/helpfunctions")}
      >
        Functions
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/helpchecks")}
      >
        Checks
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleHelpItemClick("/helpdocumentation/helpmergedfiles")
        }
      >
        Merged Files/Reports
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => handleHelpItemClick("/helpdocumentation/helpimportdata")}
      >
        Import Data
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleHelpItemClick("/helpdocumentation/helpusermanagement")
        }
      >
        User Management
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default HelpLink;
